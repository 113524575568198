<template>
  <div class="swiper-content">
    <swiper
      :modules="modules"
      :slidesPerView="4"
      :centeredSlides="flase"
      :spaceBetween="20"
      :navigation="true"
      :virtual="true"
      class="mySwiper"
      @swiper="setSwiperRef"
    >
      <swiper-slide v-for="(item, index) in dataList" :index="index"
        ><img class="imgClick" @click="handleImage(item, index)" :src="item"
      /></swiper-slide>
    </swiper>
  </div>
  <el-image-viewer
    v-if="imgPreviewUrl"
    :src="imgPreviewUrl"
    @close="closeViewer"
    :url-list="imgList"
    :initial-index="initialIndex"
  ></el-image-viewer>
</template>

<script setup>
// 引入swiper样式（按需导入）
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/virtual";
import { ref, onMounted, reactive } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination, Navigation, Virtual } from "swiper";
import { ApplicationItem } from "@/components";
let swiperRef = ref(null);
// 在modules加入要使用的模块
const modules = [Pagination, Navigation, Virtual];
const setSwiperRef = (swiper) => {
  swiperRef.value = swiper;
};
const props = defineProps({
  dataList: {
    type: Array,
    default: false,
  },
});
const slideTo = (index) => {
  swiperRef.value.slideTo(index - 1, 0);
};
onMounted(() => {
  slideTo(3);
});

let initialIndex = ref(0);
let imgList = ref([]);
let imgPreviewUrl = ref("");
// v-html 图片放大显示
function handleImage(e, index) {
  //获取点击的图片url,decodeURIComponent转码一下，防禁url转码
  imgPreviewUrl.value = decodeURIComponent(e);
  initialIndex.value = index;
  // imgPreviewUrl.value = e;
  imgList.value = props.dataList;
  //禁止遮罩层后面的内容滚动
  document.documentElement.style.overflowY = "hidden";
}
//关闭弹框
function closeViewer() {
  imgPreviewUrl.value = "";
  //恢复遮罩层后面的内容滚动
  document.documentElement.style.overflowY = "auto";
}
</script>
<style lang="scss" scoped>
.imgClick {
  cursor: pointer;
  width: 275px;
  height: 153px;
  object-fit: cover;
}
.swiper-content {
  width: 100%;
}
.swiper-content .swiper-slide {
  width: 320px;
  height: 160px;
  overflow: hidden;
  img {
    width: 100%;
  }
}
::v-deep .swiper-button-prev,
::v-deep .swiper-rtl .swiper-button-next,
::v-deep .swiper-button-next,
::v-deep .swiper-rtl .swiper-button-prev {
  width: 64px;
  height: 64px;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 32px;
}
::v-deep .swiper-button-next:after,
::v-deep .swiper-button-prev:after,
::v-deep .swiper-button-next:after,
::v-deep .swiper-button-prev:after {
  color: #ffffff;
  font-size: 26px;
}
</style>
