<template>
  <header class="headers pc-show">
    <div class="wrapper">
      <div class="logo">
        <router-link to="/web/index">
          <img src="@/assets/img/logo.png" />
        </router-link>
      </div>
      <ul class="navigation-but">
        <li>
          <router-link to="/web/index"><i class="iconfont">&#xe65c;</i>首页</router-link>
        </li>
        <li>
          <router-link to="/news"><i class="iconfont">&#xe78d;</i>AI资讯</router-link>
        </li>
        <!-- <li>
          <router-link to="/application"
            ><i class="iconfont">&#xe712;</i>AI应用</router-link
          >
        </li> -->
        <li>
          <router-link to="/connections"><i class="iconfont">&#xe704;</i>AI人脉</router-link>
        </li>
        <li>
          <router-link to="/activity"><i class="iconfont">&#xe870;</i>活动</router-link>
        </li>
        <li>
          <router-link to="/train"><i class="iconfont">&#xe876;</i>AI教育</router-link>
        </li>
        <!-- <li>
          <router-link to="/inlayPage">星河社区</router-link>
        </li> -->
        <li v-for="(item, index) in appRecommendList" :key="index">
          <router-link :to="`/application/detail?id=${item.appId}`">{{
          item.appName
        }}</router-link>
        </li>
        <!-- <li>
          <router-link to="/aiCommunity">AI社区</router-link>
        </li> -->
      </ul>
      <div>
        <ul class="user-but">
          <!-- <li @click="goDownload">
            <router-link to="/download">
              <span class="download">
                <i class="iconfont">&#xe63a;</i>
                <span>下载APP</span>
              </span>
            </router-link>
          </li> -->
          <li @click="paymentClick(open)" v-if="getToken()">
            <i title="会员" class="iconfont" :class="{ 'icon-color': userInfo.userLevel > 0 }">&#xe739;</i>
          </li>
          <li v-if="getToken()" class="position">
            <router-link to="/personal">
              <el-avatar :src="userInfo.icon
          ? userInfo.icon
          : 'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png'
          " />
            </router-link>
            <!-- <div class="nav-info-box">
              <div class="nav-info" @click="paymentClick(open)">
                {{ adaptiveStore.userLevel > 1 ? "我的会员" : "成为会员" }}
              </div>
              <div class="nav-info-icon"></div>
            </div> -->
          </li>
          <li v-else>
            <router-link to="/login"><i class="iconfont" style="margin-right: 10px">&#xe72a;</i>登录/注册</router-link>
          </li>
        </ul>
      </div>
    </div>
  </header>
  <el-dialog v-model="dialogVisible" :before-close="handleClose" title="AI门户VIP会员" custom-class="custom-class"
    :lock-scroll="false" center>
    <div>
      <div class="payment">
        <div v-for="(item, index) in paymentList" :key="index" @click="paymentClick(item)">
          <div class="payment-head" :class="{ backgColor: item.level != paymentInfo.level }">
            {{ item.name }}
          </div>
          <div v-if="item.online">
            <span>¥<span>{{ item.fee / 100 }}</span>/年</span>
            <br />
            <div class="position">
              {{ item.intactFee / 100 }}
            </div>
          </div>
          <div v-else>
            <span><span>暂未开放</span></span>
          </div>
        </div>
      </div>

      <div class="payment-bottom">
        <div :style="{ width: !paymentInfo.online ? '100%' : '32%' }">
          <h3>会员权益</h3>
          <div>1、享有定制应用大礼包（1-5款应用全年免费）；</div>
          <div>2、享有参加协会举办的线下公益（技能、创业）培训一次；</div>
          <div>3、推荐优质免费应用的信息推送（可选择订阅）；</div>
          <div>4、享有查看“人脉”普通会员及游客的信息；</div>
          <div>5、门户内拥有“黄金”头衔；</div>
          <div>6、享受其他未包含应用购买优惠折扣；</div>
        </div>
        <div class="scan-the-code" v-if="paymentInfo.online">
          <h2>扫码支付</h2>
          <div class="QRCode-box">
            <!-- <el-icon><Loading /></el-icon> -->
            <el-image v-loading="paymentState.state == 2" style="width: 188px; height: 188px"
              :src="paymentInfo.qr_code" />
            <div class="information">
              <p>¥ {{ paymentInfo.fee / 100 }}</p>
              <div>使用微信或支付宝进行扫码支付</div>
              <div>
                <i class="iconfont">&#xe662;</i>
                <i class="iconfont">&#xe60c; </i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script setup>
import { getToken } from "@/utils/auth";
import { ref, reactive, onBeforeUnmount, onMounted } from "vue";
import { ElMessage } from "element-plus";
import { getUpgradeMembership, getPayStatus } from "@/api/ai";

import useDigitalman from "@/store/adaptive";
const adaptiveStore = useDigitalman();
// 付费弹框
let paymentList = ref([]);
let paymentInfo = ref({});
let dialogVisible = ref(false);
let pollingST = ref(null);
let paymentState = reactive({ state: 1 });
let hint = ref({ 3: "成功", 4: "失败", 5: "过期" });
// 支付弹框信息
async function paymentClick(item) {
  if (item && !item.online) return;
  let res = await getUpgradeMembership({ level: item ? item.level : "" });
  let list = res.data.data;
  paymentList.value = list.levelList;
  paymentInfo.value = list.upgrade;
  polling(list.upgrade.orderNo);
  dialogVisible.value = true;
}

const userInfo = ref({});
import { getUser } from "@/utils/auth";
const appRecommendList = ref([]);
onMounted(() => {
  userInfo.value = getUser() ? JSON.parse(getUser()) : "";
  appRecommendList.value = adaptiveStore.appRecommendList
    ? adaptiveStore.appRecommendList[2]
    : [];
});

// 轮询方法
function polling(orderNo) {
  clearTimeout(pollingST.value);
  getPayStatus({ orderNo }).then((res) => {
    pollingST.value = setTimeout(() => {
      let data = res.data.data;
      paymentState.state = data.status;
      if (hint.value[data.status]) {
        ElMessage.success(hint.value[data.status]);
        handleClose();
      } else {
        clearTimeout(pollingST.value);
        polling(orderNo);
      }
    }, 2000);
  });
}
// 关闭弹框
function handleClose() {
  clearTimeout(pollingST.value);
  dialogVisible.value = false;
  location.reload();
}
onBeforeUnmount(() => {
  clearTimeout(pollingST.value);
});
</script>

<style scoped lang="scss">
.headers {
  // background: var(--anchor-color);
  background: var(--anchor-color);
  height: 80px;
  border-bottom: 1px solid var(--boder-color);
  width: 100%;
  /* min-width: 1400px; */
  position: sticky;
  top: 0;
  z-index: 999;

  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100% !important;
    padding: 0 16px;

    .logo {
      // width: 216px;
      width: 152px;
      height: 48px;
      // padding-left: 10px;
      color: #123f8d;

      &>a img {
        width: 100%;
        height: 100%;
        border-radius: 8px;
      }
    }

    .user-but {
      display: flex;
      align-items: center;

      li {
        margin-left: 16px;
        cursor: pointer;

        i {
          font-size: 24px !important;
        }
      }

      .icon-color {
        font-size: 26px;
        color: #f9a31d;
      }

      .download {
        display: flex;
        align-items: center;
        border-radius: 20px;
        padding: 0 20px;
        background: #f2f2f2;
        font-size: 18px;
        min-width: 140px;

        .iconfont {
          position: relative;
          top: 2px;
          margin-right: 2px;
        }
      }

      // justify-content: ;
    }
  }

  .navigation-but {
    display: flex;
    flex: 1;
    margin-left: 80px;
    margin-right: 80px;

    &>li {
      flex: 1;
      flex-shrink: 0;
      margin-right: 30px;
      min-width: 75px;
    }

    &>li a {
      height: 100%;
      width: 100%;
      text-align: center;
      float: left;
      line-height: 60px;
      color: var(--text-color);
      font-size: 18px;
      cursor: pointer;

      &>i {
        font-size: 16px;
        margin-right: 10px;
        vertical-align: baseline;
      }
    }

    .router-link-active,
    a:hover {
      color: #123f8d;

      i {
        color: #123f8d;
      }
    }
  }
}

// ----------------
.position {
  position: relative;
}

.position:hover .nav-info-box {
  display: block;
}

.nav-info:hover {
  background-color: #ecf5ff;
  color: #409eff;
}

.nav-info {
  padding: 10px;
  margin: 5px 0;
}

.nav-info-box {
  position: absolute;
  left: 37px;
  top: 80px !important;
  margin: auto 0;
  border-radius: 5px;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
  display: none;
  cursor: pointer;
  background: #fff;
  border: 1px solid #dcdfe6;

  .nav-info-icon {
    position: absolute;
    top: -6px;
    left: 0;
    right: 0;
    margin: auto;
    width: 10px;
    height: 10px;
    background: #fff;
    transform: rotate(45deg);
    border-left: 1px solid #dcdfe6;
    border-top: 1px solid #dcdfe6;
  }
}

// ------------------
// 付费弹框
.backgColor {
  background: url("../../assets/img/noPayment.png") 0 100% !important;
}

.payment {
  display: flex;
  justify-content: space-around;

  // min-width: 740px;
  >div {
    cursor: pointer;
    width: 33.3%;
    margin: 0 18px;
    border-radius: 10px;
    overflow: hidden;

    // border: 1px solid #000;
    .payment-head {
      font-weight: 700;
      font-size: 16px;
      color: #333333;
      text-align: center;
      line-height: 50px;
      background: url("../../assets/img/payment.png") 0 100%;
    }

    div:nth-child(2) {
      text-align: center;
      padding: 10px;
      border-left: 1px solid #797979;
      border-right: 1px solid #797979;
      border-bottom: 1px solid #797979;
      border-radius: 0 0 10px 10px;

      span {
        font-weight: bolder;
      }

      span:nth-child(1) {
        font-size: 16px;
        color: #d9001b;

        span {
          font-size: 26px;
        }
      }

      .position {
        font-size: 20px;
        font-weight: bolder;
        text-decoration: line-through;
      }
    }
  }
}

.payment-bottom {
  display: flex;
  justify-content: space-around;
  padding: 18px 18px 0;

  >div:nth-child(1) {
    width: 32%;
  }

  >div:nth-child(2) {
    width: 68%;
  }

  .scan-the-code {
    margin: 23px 0 0 30px;
    background: #f2f2f2;
    padding: 10px;
  }

  .QRCode-box {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;

    img {
      width: 188px;
      height: 188px;
    }

    .information {
      flex: 1;
      margin-left: 50px;

      p {
        color: #333333;
        font-weight: bolder;
        font-size: 32px;
      }

      i {
        font-size: 28px;
      }

      i:nth-child(1) {
        margin-right: 10px;
        color: #6cc653;
      }

      i:nth-child(2) {
        color: #51b1f7;
      }
    }
  }
}
</style>
