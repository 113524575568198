import service, { upload } from '@/utils/request'
import aistudioService from '@/utils/requestForm'
import { getToken } from '@/utils/auth'
import axios from 'axios'
// import qs from "qs";

export function uploadImg(url, formData) {
  const uploadUrl = process.env.VUE_APP_BASE_URI + url;
  let config = {
    headers: {
      Authorization: "Bearer" + getToken(),
      ContentType:
        "multipart/form-data; boundary=----WebKitFormBoundarynl6gT1BKdPWIejNq",
    }
  }
  return axios.post(uploadUrl, formData, config)
}

// 星河（飞浆）
export function getTags(params) {
  return service({
    url: '/aistudio/tags/',
    headers: {
      isToken: true
    },
    method: 'get',
    params
  })
}

export function getServingList(params) {
  return service({
    url: '/aistudio/appList/',
    headers: {
      isToken: true
    },
    method: 'get',
    params
  })
  // const uploadUrl = 'https://aistudio.baidu.com/studio/project/serving/v2/list';
  // const uploadUrl = 'http://test.insigma.chat:8198/appList';
  // let config = {
  //   headers: {
  //     withCredentials: true,
  //     'Accept': "*/*",
  //     "Content-Type": "application/json;charset=UTF-8",
  //     'Connection': 'keep-alive',
  //     'Content-Security-Policy': 'frame-ancestors https://*.baidu.com/'
  //   }
  // }
  // return axios.get(uploadUrl, { params })
}

//登录
export function login(data) {
  return service({
    url: '/login/index/',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data
  })
}

// 获取微信扫码链接接口
export function logoWxauth(params) {
  return service({
    url: '/common/wxauth/',
    headers: {
      isToken: true
    },
    method: 'get',
    params
  })
}

// 微信解绑
export function wechatUnbind(params) {
  return service({
    url: '/user/wechatUnbind/',
    headers: {
      isToken: true
    },
    method: 'get',
    params
  })
}

// 发送验证码接口
export function verifyCode(data) {
  return service({
    url: '/login/sendVerifyCode/',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data
  })
}

// 手机号验证码登录接口
export function phoneLogin(data) {
  return service({
    url: '/login/phoneLogin/',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data
  })
}

// 绑定手机号接口 /user/bindPhone/
export function bindPhone(params) {
  return service({
    url: '/user/bindPhone/',
    headers: {
      isToken: true
    },
    method: 'get',
    params
  })
}

//注册
export function loginRegister(data) {
  return service({
    url: '/login/register/',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data
  })
}

//登出
export function logout() {
  return service({
    url: '/login/logout/',
    headers: {
      isToken: true
    },
    method: 'get'
  })
}

//修改密码
export function updatePwd(data) {
  return service({
    url: '/user/updatePwd/',
    headers: {
      isToken: true
    },
    method: 'post',
    data: data
  })
}

// 个人中心

// 支付弹框信息
export function getUpgradeMembership(params) {
  return service({
    url: '/user/UpgradeMembership/',
    headers: {
      isToken: true
    },
    method: 'get',
    params
  })
}
// 支付状态 获取订单状态
export function getPayStatus(params) {
  return service({
    url: '/pay/status/',
    headers: {
      isToken: true
    },
    method: 'get',
    params
  })
}

// 获取推广信息接口
export function getPromotionInfo(params) {
  return service({
    url: '/user/getPromotionInfo/',
    headers: {
      isToken: true
    },
    method: 'get',
    params
  })
}

// 获取邀请列表接口
export function getInviteList(params) {
  return service({
    url: '/user/getInviteList/',
    headers: {
      isToken: true
    },
    method: 'get',
    params
  })
}

// 个人信息
export function userInfoChange(data) {
  return service({
    url: '/user/userInfo/',
    headers: {
      isToken: true
    },
    method: 'post',
    data: data
  })
}

// 个人信息详情
export function getUserInfo() {
  return service({
    url: '/user/getUserInfo/',
    headers: {
      isToken: true
    },
    method: 'get'
  })
}

// 企业信息
export function companyInfoChange(data) {
  return service({
    url: '/user/companyInfo/',
    headers: {
      isToken: true
    },
    method: 'post',
    data: data
  })
}

// 个人信息详情
export function getCompanyInfo(params) {
  return service({
    url: `/user/getCompanyInfo/`,
    headers: {
      isToken: true
    },
    method: 'get',
    params: params
  })
}

// 我的应用
export function myAppList(params) {
  return service({
    url: '/app/myApp/',
    headers: {
      isToken: true
    },
    method: 'get',
    params: params
  })
}

// 我的应用
export function vipAppList(params) {
  return service({
    url: '/app/vipApp/',
    headers: {
      isToken: true
    },
    method: 'get',
    params: params
  })
}


// 删除历史工具应用
export function delTrialRecord(params) {
  return service({
    url: '/app/delTrialRecord/',
    headers: {
      isToken: true
    },
    method: 'get',
    params: params
  })
}


// 我的收藏
export function collectChange(data) {
  return service({
    url: '/user/collect/',
    headers: {
      isToken: true
    },
    method: 'post',
    data: data
  })
}

// 问题反馈
export function feedbackChange(data) {
  return service({
    url: '/user/feedback/',
    headers: {
      isToken: true
    },
    method: 'post',
    data: data
  })
}

// 我的应用
// 上架分类
export function getCategory(params) {
  return service({
    url: '/app/category/',
    headers: {
      isToken: true
    },
    method: 'get',
    params
  })
}

// 上架接口
export function applyAppChange(data) {
  return service({
    url: '/app/applyApp/',
    headers: {
      isToken: true
    },
    method: 'post',
    data: data
  })
}

// 下架接口
export function appDelist(params) {
  return service({
    url: '/app/delist/',
    headers: {
      isToken: true
    },
    method: 'get',
    params
  })
}


// 获取详情 
export function getApplyAppInfo(params) {
  return service({
    url: `/app/appInfo/`,
    headers: {
      isToken: true
    },
    method: 'get',
    params
  })
}

// 应用（轮播图、排行）
export function getHomeList() {
  return service({
    url: '/app/home/',
    headers: {
      isToken: true
    },
    method: 'get'
  })
}

// 应用列表
export function getAppList(params) {
  return service({
    url: '/app/appList/',
    headers: {
      isToken: true
    },
    method: 'get',
    params: params
  })
}

// 应用列表（我的Ai应用）
export function appTrialList(params) {
  return service({
    url: '/app/appTrialList/',
    headers: {
      isToken: true
    },
    method: 'get',
    params: params
  })
}

// 应用试用
export function appTrial(data) {
  return service({
    url: '/app/appTrial/',
    headers: {
      isToken: true
    },
    method: 'get',
    params: data
  })
}


// 应用详情
export function getAppInfo(id) {
  return service({
    url: `/app/appInfo/?appId=${id}`,
    headers: {
      isToken: true
    },
    method: 'get'
  })
}

// 获取应用媒体接口
export function getAppMedia(params) {
  return service({
    url: `/app/appMedia/`,
    headers: {
      isToken: true
    },
    method: 'get',
    params
  })
}


// 首页（轮播图、排行）
export function getHomePageList() {
  return service({
    url: '/common/home/',
    headers: {
      isToken: true
    },
    method: 'get'
  })
}

// 推荐应用接口
export function getRecommend() {
  return service({
    url: '/app/recommend/',
    headers: {
      isToken: true
    },
    method: 'get'
  })
}

// 新首页
export function getCommonNewHome() {
  return service({
    url: '/common/newHome/',
    headers: {
      isToken: true
    },
    method: 'get'
  })
}

// 首页-链接
export function getHomeFooter(id) {
  return service({
    url: `/common/homeFooter/`,
    headers: {
      isToken: true
    },
    method: 'get'
  })
}


// 项目通用-点赞、点踩、收藏
export function praise(data) {
  return service({
    url: '/app/praise/',
    headers: {
      isToken: true
    },
    method: 'post',
    data: data
  })
}

// 人脉通用-点赞、点踩、收藏
export function socialCollection(data) {
  return service({
    url: 'social/collection/',
    headers: {
      isToken: true
    },
    method: 'post',
    data: data
  })
}

// 活动通用-点赞、点踩、收藏
export function activityCollection(data) {
  return service({
    url: 'activity/collection/',
    headers: {
      isToken: true
    },
    method: 'post',
    data: data
  })
}

// 教育通用-点赞、点踩、收藏
export function trainCollection(data) {
  return service({
    url: 'train/collection/',
    headers: {
      isToken: true
    },
    method: 'post',
    data: data
  })
}

// 资讯主页
export function newsHome() {
  return service({
    url: '/news/home/',
    headers: {
      isToken: true
    },
    method: 'get'
  })
}

// 新资讯首页
export function newHome() {
  return service({
    url: '/news/newHome/',
    headers: {
      isToken: true
    },
    method: 'get'
  })
}

// 资讯列表
export function newList(params) {
  return service({
    url: '/news/newsList/',
    headers: {
      isToken: true
    },
    method: 'get',
    params: params
  })
}

// 资讯详情
export function newInfo(id) {
  return service({
    url: `/news/newsInfo/?articleId=${id}`,
    headers: {
      isToken: true
    },
    method: 'get'
  })
}

// 资讯类型
export function newCategory() {
  return service({
    url: '/news/category/',
    headers: {
      isToken: true
    },
    method: 'get'
  })
}


// 人脉主页
export function socialHome() {
  return service({
    url: '/social/home/',
    headers: {
      isToken: true
    },
    method: 'get'
  })
}

// 人脉列表
export function socialList(params) {
  return service({
    url: '/social/socialCircle/',
    headers: {
      isToken: true
    },
    method: 'get',
    params: params
  })
}


// 活动主页
export function activityHome() {
  return service({
    url: '/activity/home/',
    headers: {
      isToken: true
    },
    method: 'get'
  })
}

//活动分类
export function getActiveCategory() {
  return service({
    url: '/activity/category/',
    headers: {
      isToken: true
    },
    method: 'get'
  })
}

// 活动列表
export function activityList(params) {
  return service({
    url: '/activity/activityList/',
    headers: {
      isToken: true
    },
    method: 'get',
    params: params
  })
}

//活动详情
export function getActiveInfo(id) {
  return service({
    url: `/activity/activityInfo/?activityId=${id}`,
    headers: {
      isToken: true
    },
    method: 'get'
  })
}

//教育主页
export function studyHome() {
  return service({
    url: '/train/home/',
    headers: {
      isToken: true
    },
    method: 'get'
  })
}

//教育分类
export function getstudyCategory() {
  return service({
    url: '/train/category/',
    headers: {
      isToken: true
    },
    method: 'get'
  })
}

// 教育列表
export function studyList(params) {
  return service({
    url: '/train/trainList/',
    headers: {
      isToken: true
    },
    method: 'get',
    params: params
  })
}

//教育详情
export function getTrainInfo(id) {
  return service({
    url: `/train/trainInfo/?trainId=${id}`,
    headers: {
      isToken: true
    },
    method: 'get'
  })
}

// 获取省市区
export function cityMap() {
  return service({
    url: '/common/cityMap/',
    headers: {
      isToken: true
    },
    method: 'get'
  })
}

// 获取省市区
export function getCompanyByName() {
  return service({
    url: '/common/company/',
    headers: {
      isToken: true
    },
    method: 'get'
  })
}


// 移动端图片上传
export function uploadCommon(data, headers = {}) {
  return upload({
    url: 'common/upload/',
    headers,
    method: 'post',
    data: data
  })
}


// 获取token
export function openToken(params) {
  return service({
    url: '/user/openToken/',
    headers: {
      isToken: true
    },
    method: 'get',
    params
  })
}

/**
 * 社区接口部分
*/
// 社区首页接口
export function fetchCommunityIndex() {
  return service({
    url: '/community/index/',
    method: 'get',
  })
}

// 获取帖子列表
export function fetchCommunityPostList(data) {
  return service({
    url: '/community/postList/',
    method: 'get',
    headers: {
      isToken: true
    },
    params: data
  })
}

// 获取话题
export function fetchTopicList() {
  return service({
    url: '/community/getTopic/',
    method: 'get',
  })
}

// 获取话题分类接口
export function fetchTopicCategory(topicId) {
  return service({
    url: '/community/getTopicCategory/',
    method: 'get',
    params: { topicId }
  })
}

// 获取标签接口
export function fetchLabels(payload) {
  return service({
    url: '/community/getLabels/',
    method: 'get',
    params: {
      search: payload
    }
  })
}

// 获取所有AI资料
export function fetchAllAiData() {
  return service({
    url: '/community/getFiles/',
    method: 'get',
  })
}

// 获取文件详情
export function fetchAiDataById(fileId) {
  return service({
    url: '/community/getFileInfo/',
    method: 'get',
    headers: {
      isToken: true
    },
    params: { fileId }
  })
}

// 发布新帖
export function fetchCreatePost(data) {
  return service({
    url: '/community/createPost/',
    method: 'post',
    headers: {
      isToken: true
    },
    data
  })
}

// 帖子详情
export function fetchCommunityPostInfoByid(postId) {
  return service({
    url: '/community/postInfo/',
    method: 'get',
    headers: {
      isToken: true
    },
    params: {
      postId
    }
  })
}

// 帖子点赞收藏
export function fetchCommunityLikePost(data) {
  return service({
    url: '/community/likePost/',
    method: 'post',
    headers: {
      isToken: true
    },
    data
  })
}

// 添加评论接口
export function fetchAddComment(data) {
  return service({
    url: '/community/addComment/',
    method: 'post',
    headers: {
      isToken: true
    },
    data
  })
}

// 编辑评论接口
export function fetchEditComment(data) {
  return service({
    url: '/community/editComment/',
    method: 'post',
    headers: {
      isToken: true
    },
    data
  })
}

// 删除评论接口
export function fetchDelComment(commentId) {
  return service({
    url: '/community/delComment/',
    method: 'get',
    headers: {
      isToken: true
    },
    params: {
      commentId
    }
  })
}

// 删除帖子接口
export function fetchDelPost(postId) {
  return service({
    url: '/community/delPost/',
    method: 'get',
    headers: {
      isToken: true
    },
    params: {
      postId
    }
  })
}

// 获取评论接口
export function fetchGetCommentList(data) {
  return service({
    url: '/community/getCommentList/',
    method: 'get',
    headers: {
      isToken: true
    },
    params: data
  })
}

// AI智能搜索
export function fetchAiSearchList(data) {
  return service({
    url: '/app/aiSearch/',
    method: 'get',
    params: data
  })
}

// 角色广场  全部角色
export function fetchAllAgentList(data) {
  return service({
    url: '/agent/apps/',
    method: 'get',
    params: data
  })
}

// 角色广场  我的角色
export function fetchMyAgentList(data) {
  return service({
    url: '/agent/myApps/',
    method: 'get',
    headers: {
      isToken: true
    },
    params: data
  })
}

// 图片广场
export function fetchAiCollectList(data) {
  return service({
    url: '/agent/aiCollect/',
    method: 'get',
    params: data
  })
}

// 创建智能体
export function fetchCreateAgent(data) {
  return service({
    url: '/agent/createApps/',
    method: 'post',
    headers: {
      isToken: true
    },
    data
  })
}

// 删除智能体
export function fetchDeleteAgent(data) {
  return service({
    url: '/agent/delApps/',
    method: 'get',
    headers: {
      isToken: true
    },
    params: data
  })
}

// 编辑智能体
export function fetchModifyAgent(data) {
  return service({
    url: '/agent/modifyApps/',
    method: 'post',
    headers: {
      isToken: true
    },
    data
  })
}

// 对话接口
export function fetchChatAgent(data) {
  return service({
    url: '/agent/chat/',
    method: 'post',
    headers: {
      isToken: true,
    },
    data
  })
}

// 对话历史
export function fetchChatMessageAgent(data) {
  return service({
    url: '/agent/chatMessage/',
    method: 'post',
    headers: {
      isToken: true
    },
    data
  })
}

// 公众号扫码登录接口  type=login
export function wechatOffiaccountLogin(data) {
  return service({
    url: '/login/wechatOffiaccountLogin/',
    method: 'get',
    params: data
  })
}

// 绑定
export function wechatOffiaccountBind(data) {
  return service({
    url: '/login/wechatOffiaccountLogin/',
    headers: {
      isToken: true
    },
    method: 'get',
    params: data
  })
}

// 获取二维码状态接口  ticket
export function getQrStatus(data) {
  return service({
    url: '/login/getQrStatus/',
    method: 'get',
    params: data
  })
}

// 文生图
export function fetchText2Img(data) {
  return service({
    url: '/agent/text2img/',
    method: 'post',
    headers: {
      isToken: true
    },
    data
  })
}

// 获取我的生图接口
export function getMyCollect() {
  return service({
    url: '/agent/myCollect/',
    method: 'get',
    headers: {
      isToken: true
    },
  })
}

// 删除文生图
export function fetchDelText2Img(params) {
  return service({
    url: '/agent/delMyCollect/',
    method: 'get',
    headers: {
      isToken: true
    },
    params
  })
}