<template>
  <div class="data-item" :class="type">
    <div class="top">
      <div class="name ellipsis">{{ slideList.labels[0] }}</div>
      <div class="company ellipsis">
        <i class="iconfont">&#xe65a;</i> {{ slideList.companyName }}
      </div>
    </div>
    <h5 class="title">
      <router-link :to="'/application/detail?id=' + slideList.appId">{{
        slideList.appName
      }}</router-link>
    </h5>
    <div class="prod-img">
      <!-- v-if="vipState" -->
      <router-link :to="'/application/detail?id=' + slideList.appId"
        ><img :src="slideList.cover"
      /></router-link>
      <!-- <img
        v-else
        class="cursor-pointer"
        :src="slideList.cover"
      /> -->
    </div>
    <div class="bottom">
      <div class="time">
        <i class="iconfont">&#xe8c4;</i> {{ slideList.issueDate }}
      </div>
      <div class="operate">
        <span @click="operateClick(1, slideList)" title="点赞"
          ><i :class="['iconfont', slideList.isPraise ? 'icon-colour' : '']"
            >&#xec8c;</i
          >
          {{ slideList.praiseNum }}</span
        >
        <!-- <span @click="operateClick(2, slideList)"
          ><i :class="['iconfont', slideList.isTrample ? 'icon-colour' : '']"
            >&#xe697;</i
          >
          {{ slideList.trampleNum }}</span
        > -->
        <span @click="operateClick(3, slideList)" title="收藏"
          ><i :class="['iconfont', slideList.isCollect ? 'icon-colour' : '']"
            >&#xe8c6;</i
          >
          {{ slideList.collectNum }}</span
        >
        <span class="focus"><i class="iconfont">&#xe618;</i></span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { praise } from "@/api/ai";
import { getUser } from "@/utils/auth";
import { ElMessage } from "element-plus";
import { PaymentDialog } from "@/components";
import { ref } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();
const props = defineProps({
  type: {
    type: String,
  },
  slideList: {
    type: Object,
  },
  vipState: {
    type: Blob,
  },
});
// 会员点击应用
function vipClick() {
  if (getUser()) {
    let user = JSON.parse(getUser());
    user.userLevel > 0
      ? router.push({
          path: `/application/detail`,
          query: { id: props.slideList.appId },
        })
      : ElMessage.success("请充值为会员");
  } else {
    ElMessage.success("请登陆后进入应用");
  }
}
async function operateClick(key, item) {
  await praise({ type: key, appId: item.appId });
  let list = props.slideList;
  if (key == 1) {
    if (item.isPraise) {
      list.praiseNum = list.praiseNum - 1;
      list.isPraise = 0;
    } else {
      list.praiseNum = list.praiseNum + 1;
      list.isPraise = 1;
      if (list.isTrample) {
        list.isTrample = 0;
        list.trampleNum = list.trampleNum - 1;
      }
    }
  }
  if (key == 2) {
    if (item.isTrample) {
      list.trampleNum = list.trampleNum - 1;
      list.isTrample = 0;
    } else {
      list.trampleNum = list.trampleNum + 1;
      list.isTrample = 1;
      if (list.isPraise) {
        list.isPraise = 0;
        list.praiseNum = list.praiseNum - 1;
      }
    }
  }
  if (key == 3) {
    if (item.isCollect) {
      list.collectNum = list.collectNum - 1;
      list.isCollect = 0;
    } else {
      list.collectNum = list.collectNum + 1;
      list.isCollect = 1;
    }
  }
}
</script>
<style lang="scss" scoped>
.icon-colour {
  color: #273d83;
}

.data-item {
  width: 460px;
  background: var(--anchor-color);
  box-shadow: 0px 0px 16px 0px var(--anchor-color);
  border-radius: 24px;
  padding: 30px;
  box-sizing: border-box;
  margin-right: 40px;
  .top {
    display: flex;
    justify-content: space-between;
    color: var(--text-color);
    margin-bottom: 10px;
    .name {
      font-size: 18px;
      width: 136px;
    }
    .company {
      // flex: 1;
      width: 200px;
      font-size: 16px;
      text-align: right;
    }
  }
  h5.title {
    font-size: 24px;
    color: var(--text-color);
    margin-bottom: 10px;
  }
  .prod-img {
    width: 320px;
    height: 320px;
    border-radius: 32px;
    overflow: hidden;
    margin: 0px auto 30px;
  }
  .prod-img img {
    width: 100%;
  }
  .bottom {
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    color: var(--text-color);
    .operate span {
      width: 30px;
      margin-right: 15px;
      cursor: pointer;
    }
    .operate span.focus {
      color: #123f8d;
    }
    i {
      font-size: 18px;
    }
    i.focus {
      color: #123f8d;
    }
  }
}
.data-item.study-b {
  height: 642px;
  flex: 1;
  .prod-img {
    width: 400px;
    height: 400px;
  }
}
.data-item.study-s {
  width: 100%;
  height: 310px;
  .prod-img {
    width: 100%;
    height: 134px;
  }
  h5.title {
    margin-bottom: 20px;
  }
  .prod-img {
    margin-bottom: 20px;
  }
}
.data-item.study-s:first-child {
  margin-bottom: 22px;
  cursor: pointer;
}
</style>
