import { fetchAiDataById, fetchAllAiData, fetchCommunityIndex, fetchLabels, fetchTopicList } from '@/api/ai'
import { defineStore } from 'pinia'

const mapFileTypeData = {
  '.pdf': 'pdf',
  '.docx': 'word',
  '.xlsx': 'excel',
  '.mp4': 'video',
  '.mp3': 'music',
  '.txt': 'txt',
  '.jpg': 'image',
  '.png': 'image',
  '.gif': 'image',
  '.jpeg': 'image',
  '.bmp': 'image',
  '.svg': 'image',
  '.webp': 'image',
}

function mapFileType(fileList) {
  return fileList.map(item => {
    const fileType = item.name.split('.').pop()
    item.type = mapFileTypeData[`.${fileType}`]
    return item
  })
}

function mapFileTypeByName(fileInfo) {
  const fileType = fileInfo.name.split('.').pop()
  fileInfo.type = mapFileTypeData[`.${fileType}`]
  return fileInfo
}

const useCommunityStore = defineStore('community', {
  state: () => ({
    // 社区首页标签
    communityHomeTags: [],
    communityHomeRecommendPost: [],
    allLabels: [],
    allAiData: [],
    fileDetail: {},
    topicsList: [],
    topicInfo: {},
    searchParams: {},
  }),
  actions: {
    // 获取社区首页数据
    async getCommunityHomeData() {
      const { data: res } = await fetchCommunityIndex()
      if (res.code === 0) {
        this.communityHomeTags = res.data.labels
        this.communityHomeRecommendPost = res.data.recommend_post
      }
    },
    // 获取所有标签
    async getAllLabels(payload) {
      console.log(payload);
      const { data: res } = await fetchLabels(payload)
      if (res.code === 0) {
        this.allLabels = res.data
      }
    },
    // 获取所有AI资料
    async getAllAiData() {
      const { data: res } = await fetchAllAiData()
      if (res.code === 0) {
        this.allAiData = mapFileType(res.data)
      }
    },
    // 获取资料详情
    async getFileDetail(fileId) {
      const { data: res } = await fetchAiDataById(fileId)
      if (res.code === 0) {
        this.fileDetail = mapFileTypeByName(res.data)
      }
    },
    // 获取话题列表
    async getTopics() {
      const { data: res } = await fetchTopicList()
      if (res.code === 0) {
        this.topicsList = res.data
      }
    },
    saveTopicInfo(item) {
      this.topicInfo = item
    },
    // 存储搜索参数
    setSearchParams(params) {
      this.searchParams = params
    }
  },
})

export default useCommunityStore