<template>
  <div v-loading="loading">
    <Toolbar style="border-bottom: 1px solid #ccc" :editor="editorRef" :defaultConfig="toolbarConfig" :mode="mode" />
    <Editor style="height: 300px; width: 100%; overflow-y: hidden;" v-model="valueHtml" :defaultConfig="editorConfig"
      :mode="mode" @onCreated="handleCreated" />
  </div>
</template>
<script setup>
import '@wangeditor/editor/dist/css/style.css' // 引入 css
import { onBeforeUnmount, ref, shallowRef, watch, nextTick } from 'vue'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import axios from 'axios';
import { getToken } from '@/utils/auth';
import { ElMessage } from 'element-plus';

const props = defineProps({
  modelValue: {
    type: String,
    default: ''
  }
})

const loading = ref(false)

const mode = ref('simple')

// 内容 HTML
const valueHtml = ref('')
const baseUrl = ref(process.env.VUE_APP_BASE_URI + "/media/")
const emit = defineEmits(['update:modelValue'])

watch(() => valueHtml.value, () => {
  emit('update:modelValue', valueHtml.value)
})

// setHtml
const setContent = (val) => {
  nextTick(() => {
    valueHtml.value = val
  })
}

// 清空内容
const clear = () => {
  valueHtml.value = ''
}

let headers = {
  Authorization: "Bearer" + getToken(),
  ContentType:
    "multipart/form-data; boundary=----WebKitFormBoundarynl6gT1BKdPWIejNq",
};
const uploadUrl =
  process.env.VUE_APP_BASE_API +
  "/common/upload/";

const editorRef = shallowRef()
const customUpload = (file, insertFn) => {
  const formData = new FormData();
  formData.append("file", file);
  loading.value = true
  axios({ // 使用原生的axios进行调用接口 就可以解决问题
    method: "post",
    headers: headers,
    url: uploadUrl,
    data: formData
  })
    .then(data => {
      const { data: res } = data
      if (res.code === 0) {
        insertFn(baseUrl.value + res.data, file.name, "");
        ElMessage({
          message: "插入成功",
          type: "success",
          showClose: true
        });
      } else {
        ElMessage({
          message: `${data.data.resp_msg}`,
          type: "warning",
          showClose: true
        });
      }
    })
    .catch(error => {
      ElMessage({
        message:
          error.resp_msg ||
          error.error ||
          "网络似乎不通畅，请检查后再试",
        type: "warning",
        showClose: true
      });
    }).finally(() => {
      loading.value = false
    })
}

const customUploadVideo = (file, insertFn) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("type", 'file');
  loading.value = true
  axios({ // 使用原生的axios进行调用接口 就可以解决问题
    method: "post",
    headers: headers,
    url: uploadUrl,
    data: formData
  })
    .then(data => {
      const { data: res } = data
      if (res.code === 0) {
        insertFn(baseUrl.value + res.data, file.name, "");
        ElMessage({
          message: "插入成功",
          type: "success",
          showClose: true
        });
      } else {
        ElMessage({
          message: `${data.data.resp_msg}`,
          type: "warning",
          showClose: true
        });
      }
    })
    .catch(error => {
      ElMessage({
        message:
          error.resp_msg ||
          error.error ||
          "网络似乎不通畅，请检查后再试",
        type: "warning",
        showClose: true
      });
    }).finally(() => {
      loading.value = false
    })
}


const toolbarConfig = {
  excludeKeys: ['insertVideo', 'uploadVideo', 'insertTable', 'fullScreen', 'undo', 'redo', 'todo'],
}
const editorConfig = {
  placeholder: '请输入内容...',
  MENU_CONF: {
    uploadImage: {
      customUpload: customUpload,
    },
    uploadVideo: {
      customUpload: customUploadVideo,
    }
  }
}


// 组件销毁时，也及时销毁编辑器
onBeforeUnmount(() => {
  const editor = editorRef.value
  if (editor == null) return
  editor.destroy()
})

const handleCreated = (editor) => {
  console.log('created', editor.toolbarKeys);
  editorRef.value = editor // 记录 editor 实例，重要！
}


defineExpose({
  clear,
  setContent
})
</script>
<style scoped lang="scss"></style>