import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import pinia from './store';
import ElementPlus from 'element-plus';
import 'element-plus/theme-chalk/index.css';
import "@/assets/css/style.scss";
import "@/assets/css/dark.css";
import "@/assets/css/light.css";
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import './permission'
// import * as ElementPlusIconsVue from '@element-plus/icons-vue'
// 分页组件
import Pagination from '@/components/Pagination'
// import locale from 'element-plus/lib/locale/lang/zh-cn'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
// import VueAwesomeSwiper from 'vue-awesome-swiper';
import vshare from 'vshare'
// import 'swiper/css';
// import VueQuillTextEditor from 'vue-quill-text-editor';
// import 'vue-quill-text-editor/dist/vue-quill-text-editor.esm.css';
import CKEditor from '@ckeditor/ckeditor5-vue';
import Vant from 'vant';
import 'vant/lib/index.css';
// import VueTouch from 'vue-touch';
// element夜间样式
import 'element-plus/theme-chalk/dark/css-vars.css';
// import { createMetaManager } from 'vue-meta';

// import $ from 'jquery' 

// ElementPlus.Dialog.props.lockScroll.default = false;
const app = createApp(App)

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
// app.use(VueQuillTextEditor)
app.component('Pagination', Pagination)
app.use(router)
// app.use(createMetaManager())
app.use(vshare)
app.use(ElementPlus, { locale: zhCn })
// app.use(ElementPlus)
// app.use(VueTouch, { name: 'v-touch' })
// app.use(VueAwesomeSwiper)
app.use(CKEditor)
app.use(Vant)
app.use(pinia)
app.mount('#app')


