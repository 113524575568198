<template>
  <div
    class="data-item"
    :style="{ width: width }"
    :class="appType == 1 ? 'undertone' : ''"
  >
    <div class="tag" v-if="appType == 1" :class="'tag' + tagList.status">
      {{ objStatus[tagList.status] }}
    </div>
    <div class="tag" v-if="appType == 2 && tagList.status != 2" :class="'tag0'">
      已下架
    </div>
    <div class="img-size" :class="{ 'cursor-pointer': !isSkip }">
      <img @click="coverImgClick" :src="tagList.coverImg" />
    </div>
    <div class="content">
      <div class="name" @click="coverImgClick">
        <img :src="tagList.iconUrl" class="img-icon" />
        <span>
          {{ tagList.name }}
        </span>
        <i v-if="tagList.isVipApp" class="iconfont">&#xe65d;</i>
      </div>
      <div class="tags">
        <div class="tags-box">
          <em v-for="(item, index) in tagList.labels" :key="index">{{
            item
          }}</em>
        </div>
      </div>
      <!-- <div class="txt">
        <i class="iconfont">&#xe65e;</i><span>{{ tagList.companyName }}</span>
      </div>
      <div class="txt">
        <i class="iconfont">&#xe8c4;</i><span>{{ tagList.createDate }}</span>
      </div> -->
      <div class="bottom">
        <h5 class="name">{{ tagList.category }}</h5>
        <div class="operate">
          <span
            :class="{ 'cursor-pointer': !isGiveALike && !isCollect }"
            @click="operateClick(1, tagList)"
            title="点赞"
            ><i :class="['iconfont', tagList.isPraise ? 'icon-colour' : '']"
              >&#xec8c;</i
            >
            {{ tagList.praiseNum }}</span
          >
          <!-- <span
            :class="{ 'cursor-pointer': !isGiveALike && !isCollect }"
            @click="operateClick(2, tagList)"
            ><i :class="['iconfont', tagList.isTrample ? 'icon-colour' : '']"
              >&#xe697;</i
            >
            {{ tagList.trampleNum }}</span
          > -->
          <span
            :class="{ 'cursor-pointer': !isGiveALike }"
            @click="operateClick(3, tagList)"
            title="收藏"
            ><i :class="['iconfont', tagList.isCollect ? 'icon-colour' : '']"
              >&#xe8c6;</i
            >
            {{ tagList.collectionNum }}</span
          >
          <span class="focus"><i class="iconfont">&#xe618;</i></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup name="MineapplicationItem">
import { defineEmits, defineProps, onMounted, ref } from "vue";
import { praise } from "@/api/ai";
import { useRouter } from "vue-router";
let objStatus = {
  0: "未上架",
  1: "审核中",
  2: "已上架",
  3: "上架失败",
};
let isSkip = ref(false);
let isGiveALike = ref(false);
let isCollect = ref(false);

const props = defineProps({
  tagList: {
    type: Object,
    default: {},
  },
  appType: {
    type: Number,
    default: 0,
  },
  width: {
    type: String,
    default: "285px",
  },
});
onMounted(() => {
  if (
    props.appType == 2 &&
    props.tagList.status != 2 &&
    !props.tagList.isCreator
  ) {
    isSkip.value = true;
  }
  // 我的应用-下架不能点赞操作
  if (props.appType == 1 && props.tagList.status != 2) {
    isGiveALike.value = true;
  }
  // 我的收藏-下架不能收藏
  if (props.appType == 2 && props.tagList.status != 2) {
    isCollect.value = true;
  }
});
const router = useRouter();
const emit = defineEmits(["change-tagList"]);
function coverImgClick() {
  if (isSkip.value) {
    return;
  }
  if (props.tagList.status == 2 || props.appType == 0) {
    router.push({
      path: "/application/detail",
      query: { id: props.tagList.appId },
    });
  } else {
    router.push({
      path: "/personal/application/listing",
      query: { id: props.tagList.appId },
    });
  }
}
async function operateClick(key, item) {
  // 我的应用-下架不能点赞操作
  if (props.appType == 1 && props.tagList.status != 2) return;
  // 我的收藏-下架不能收藏
  if (props.appType == 2 && props.tagList.status != 2 && key != 3) return;
  await praise({ type: key, appId: item.appId });
  let list = props.tagList;
  if (key == 1) {
    if (item.isPraise) {
      list.praiseNum = list.praiseNum - 1;
      list.isPraise = 0;
    } else {
      list.praiseNum = list.praiseNum + 1;
      list.isPraise = 1;
      if (list.isTrample) {
        list.isTrample = 0;
        list.trampleNum = list.trampleNum - 1;
      }
    }
  }
  if (key == 2) {
    if (item.isTrample) {
      list.trampleNum = list.trampleNum - 1;
      list.isTrample = 0;
    } else {
      list.trampleNum = list.trampleNum + 1;
      list.isTrample = 1;
      if (list.isPraise) {
        list.isPraise = 0;
        list.praiseNum = list.praiseNum - 1;
      }
    }
  }
  if (key == 3) {
    if (item.isCollect) {
      list.collectionNum = list.collectionNum - 1;
      list.isCollect = 0;
    } else {
      list.collectionNum = list.collectionNum + 1;
      list.isCollect = 1;
    }
  }
  emit("change-tagList", list);
}
</script>

<style lang="scss" scoped>
.icon-colour {
  color: #273d83;
}
.undertone {
  // width: 32.6% !important;
  // background: #f5f5f5 !important;
  background: var(--min-box-color) !important;
}
.data-item {
  width: 280px;
  // height: 298px;
  border-radius: 8px;
  margin: 0 0px 16px 0;
  background: var(--anchor-color);
  // background: var(--min-box-color);
  float: left;
  position: relative;
  overflow: hidden;
}
.tag {
  position: absolute;
  top: 2px;
  right: -30px;
  height: 40px;
  line-height: 50px;
  width: 90px;
  background: #0070ec;
  color: #fff;
  font-size: 12px;
  transform: rotate(45deg);
  text-align: center;
  top: -4px;
  right: -30px;
  color: #ffffff;
}
.tag0 {
  background: rgb(86, 87, 86);
}
.tag1 {
  background: #ffc220;
}
.tag2 {
  background: #0070ec;
}
.tag3 {
  background: #ff2840;
}
// .data-item:nth-child(3n -1) {
//   margin: 0 0px 16px;
// }

.cursor-pointer {
  cursor: pointer !important;
}
.img-size {
  width: 100%;
  height: 144px;
  border-radius: 8px;
  overflow: hidden;
  img {
    width: 100%;
    min-height: 144px;
  }
}
.content {
  padding: 10px;

  .img-icon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    float: left;
    border-radius: 4px;
  }
  .name {
    margin-bottom: 5px;
    cursor: pointer;
    height: 20px;
    span {
      line-height: 20px;
      font-size: 18px;
      color: var(--text-color);
      word-break: keep-all;
      white-space: nowrap;
      overflow: hidden;
      display: block;
      max-width: 215px;
      float: left;
      text-overflow: ellipsis;
    }
    i {
      color: #ffc414;
      position: relative;
      top: -3px;
      padding-left: 3px;
      // vertical-align: middle;
    }
  }
  .tags {
    overflow-x: auto;
    margin-bottom: 5px;
    height: 23px;
    .tags-box {
      display: flex;
      // flex-wrap: wrap;
    }
    em {
      border: 1px solid var(--boder-color);
      display: inline-block;
      height: 20px;
      line-height: 20px;
      border-radius: 2px;
      font-style: normal;
      padding: 0px 10px;
      color: var(--text-color);
      background: var(--anchor-color);
      font-size: 12px;
      margin-right: 10px;
    }
  }

  .txt {
    line-height: 24px;
    i {
      vertical-align: baseline;
      margin-right: 3px;
    }
  }
}
.bottom {
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  color: var(--text-color);
  align-items: center;
  margin-top: 5px;
  h5.name {
    color: #123f8d;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 0px;
  }
  .operate span {
    width: 30px;
    margin-right: 15px;
    cursor: default;
  }
  .operate span.focus {
    color: #123f8d;
  }
  i {
    font-size: 18px;
  }
  i.focus {
    color: #123f8d;
  }
}
</style>
