<template>
  <div class="data-item point" v-if="list.uid">
    <!-- :class="{ point: list.isCollect == 1 }" -->
    <div class="card-top" @click="dialogDetail(list)">
      <!-- img -->
      <div class="card-img">
        <em :class="'level' + list.userLevel">{{
          levelList[list.userLevel]
        }}</em>
        <el-image
          class="card-img-size"
          :src="list.icon ? baseUrl + list.icon : defaultIcon"
          fit="cover"
        />
      </div>
      <div class="card-info">
        <div>
          <h2>{{ list.userName }}</h2>
          <span>{{ list.operatingPost }}</span>
        </div>
        <div>{{ list.intro }}</div>
        <div>
          <i class="iconfont">&#xe8c4;</i>
          <span>{{ parseTime(list.vcard_time, "{y}-{m}-{d}") }}</span>
        </div>
      </div>
    </div>
    <div class="card-bottom">
      <div class="address">{{ list.cityName + list.districtName }}</div>
      <div
        :class="{ 'collect-on': list.isCollect, collect: true }"
        @click="collectClcik(list)"
      >
        <i class="iconfont" title="收藏">&#xe8c6;</i> <span>收藏 </span>
      </div>
    </div>
    <div class="tag">个人</div>
  </div>

  <div class="data-item" :class="{ point: list.isCollect == 1 }" v-else>
    <div class="card-top" @click="dialogDetail(list)">
      <div class="card-img">
        <el-image
          class="card-img-size"
          :src="list.icon ? baseUrl + list.icon : defaultIcon"
          fit="cover"
        />
      </div>
      <div class="card-info">
        <div>
          <h2>{{ list.companyName }}</h2>
        </div>
        <div>{{ list.intro }}</div>
        <div>
          <i class="iconfont">&#xe8c4;</i>
          <span>{{ parseTime(list.vcard_time, "{y}-{m}-{d}") }}</span>
        </div>
      </div>
    </div>
    <div class="card-bottom">
      <div class="address">{{ list.cityName + list.districtName }}</div>
      <div
        :class="{ 'collect-on': list.isCollect, collect: true }"
        @click="collectClcik(list)"
      >
        <i class="iconfont" title="收藏">&#xe8c6;</i> <span>收藏</span>
      </div>
    </div>
    <div class="tag comp">公司</div>
  </div>
</template>

<script setup>
import { defineProps, ref, defineEmits } from "vue";
import { parseTime } from "@/utils/ai";
import { socialCollection } from "@/api/ai";
const emit = defineEmits(["change-tagList", "dailog-value"]);
const defaultIcon = require("@/assets/img/icon.png");
defineProps({
  list: {
    type: Object,
    default: {},
  },
});
let baseUrl = ref(process.env.VUE_APP_BASE_URI + "/media/");
let levelList = {
  0: "普通",
  1: "体验",
  2: "高级",
  3: "特级",
  4: "VIP",
};
import { Action } from "element-plus";
import { ElMessageBox } from "element-plus";
const open = () => {
  ElMessageBox.alert("请收藏后查看详情", "人脉详情", {
    confirmButtonText: "确定",
    callback: (Action) => {
      // ElMessage({
      //   type: "info",
      //   message: `action: ${Action}`,
      // });
    },
  });
};
// 弹框
function dialogDetail(list) {
  emit("dailog-value", list);

  // if (list.isCollect == 1 || list.isOwner) {
  //   emit("dailog-value", list);
  // } else {
  //   open();
  //   return;
  // }
}
// 收藏
async function collectClcik(list) {
  await socialCollection({ uid: list.uid, type: 3, cid: list.cid });
  if (list.isCollect == 0) {
    list.isCollect = 1;
  } else {
    list.isCollect = 0;
  }
  emit("collect-list", list);
}
</script>
<style lang="scss" scoped>
.data-item {
  padding: 10px;
  width: 388px;
  height: 154px;
  background: var(--anchor-color);
  border-radius: 16px;
  position: relative;
  overflow: hidden;
  &.point .card-top {
    cursor: pointer;
  }
  .tag {
    position: absolute;
    top: 2px;
    right: -30px;
    height: 25px;
    line-height: 25px;
    width: 90px;
    background: #0070ec;
    color: #fff;
    font-size: 12px;
    transform: rotate(45deg);
    text-align: center;
  }
  .tag.comp {
    background: #123f8d;
  }
  .card-top {
    display: flex;
  }
  .card-img {
    position: relative;
    width: 100px;
    height: 100px;
    margin-right: 10px;
    em {
      position: absolute;
      bottom: 0px;
      left: 0px;
      width: 100%;
      z-index: 1;
      display: block;
      font-style: normal;
      font-size: 12px;
      border-radius: 8px;
      height: 16px;
      line-height: 16px;
      text-align: center;
      color: #fff;
      background: #123f8d;
    }
    em.level1 {
      background: rgba($color: #123f8d, $alpha: 0.7);
    }
    em.level2 {
      background: rgba($color: #cb571c, $alpha: 0.7);
    }
    em.level3 {
      background: rgba($color: #8f93aa, $alpha: 0.7);
    }
    em.level4 {
      background: rgba($color: #ffc220, $alpha: 0.7);
    }
  }
  .card-img-size {
    width: 100px;
    height: 100px;
    border-radius: 8px;
    margin-right: 10px;
  }
  .card-info {
    flex: 1;
  }
  .card-info > div:nth-child(1) {
    overflow: hidden;
    & > h2 {
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: var(--text-color);
      float: left;
      line-height: 30px;
      max-width: 250px;
      word-break: keep-all;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    & > span {
      font-size: 14px;
      font-weight: 400;
      color: var(--text-color);
      line-height: 30px;
      margin-left: 10px;
    }
  }
  .card-info > div:nth-child(2) {
    margin: 8px 0 16px 0;
    font-size: 16px;
    font-weight: 400;
    color: var(--text-color);
    line-height: 24px;
    width: 260px;
    word-break: keep-all;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .card-info > div:nth-child(3) {
    & > i {
      font-size: 12px;
    }
    & > span {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: var(--text-color);
      line-height: 22px;
      vertical-align: middle;
    }
  }
  .card-bottom {
    margin-top: 8px;
    display: flex;
    .address {
      flex: 1;
      font-size: 14px;
      font-weight: 400;
      color: var(--text-color);
      line-height: 22px;
    }
    .collect {
      cursor: pointer;
      & > i {
        font-size: 14px;
        color: var(--text-color);
      }
      & > span {
        font-size: 12px;
        font-weight: 400;
        color: var(--text-color);
        line-height: 18px;
        vertical-align: middle;
      }
    }
    .collect-on {
      i {
        color: #ffc220;
      }
    }
  }
}
</style>
