<template>
  <div class="swiper-content">
    <swiper
      :modules="modules"
      :slidesPerView="1"
      :centeredSlides="flase"
      :spaceBetween="105"
      :virtual="true"
      :navigation="true"
      class="mySwiper"
      @swiper="setSwiperRef"
    >
      <swiper-slide
        v-for="(item, index) in dataList"
        :index="index"
        :key="index"
        style="position: relative"
        class="video-box"
      >
        <!-- <img :src="item" @click="handleImage(item, index)" /> -->
        <!-- <el-image class="imgClick" :src="item.videoImg"> </el-image> -->
        <video class="video-dialog" :src="item.link" />
        <i class="iconfonts video-icon" @click="videoClick(item)">&#xe610;</i>
        <div class="video-title">{{ item.title }}</div>
      </swiper-slide>
    </swiper>
  </div>
  <van-dialog
    v-model:show="visible"
    :title="videoData.title"
    :before-close="handleClose"
    close-on-click-overlay
    :show-confirm-button="false"
  >
    <video class="video-dialog" :src="videoData.link" autoplay controls />
  </van-dialog>
</template>

<script setup>
// 引入swiper样式（按需导入）
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/virtual";
import { ref, onMounted } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination, Navigation, Virtual } from "swiper";
import { ApplicationItem } from "@/components";
let swiperRef = ref(null);
// 在modules加入要使用的模块
const modules = [Pagination, Navigation, Virtual];
const setSwiperRef = (swiper) => {
  swiperRef.value = swiper;
};
const props = defineProps({
  dataList: {
    type: Array,
    default: false,
  },
});
const slideTo = (index) => {
  swiperRef.value.slideTo(index - 1, 0);
};
onMounted(() => {
  slideTo(1);
});
let visible = ref(false);
let videoData = ref("");
// 视频弹框
function videoClick(item) {
  videoData.value = item;
  visible.value = true;
}
function handleClose() {
  videoData.value = "";
  visible.value = false;
}
</script>
<style lang="scss" scoped>
body,
html {
  line-height: 0rem;
}
.video-box {
  position: relative;
  height: 1.58rem;
  .video-title {
    text-align: center;
  }
}
.imgClick {
  width: 100%;
  max-height: 100%;
  // object-fit: cover;
}

.video-icon {
  display: block;
  width: 0.6rem;
  height: 0.6rem;
  font-size: 0.55rem !important;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0.6rem auto;
  line-height: 0.6rem;
}
.video-dialog {
  // width: 3.2rem;
  width: 100%;
  max-height: 1.7rem;
}

.swiper-content {
  width: 100%;
}

.swiper-content .swiper-slide {
  width: 1.2rem !important;
  height: 0.6rem !important;
  overflow: hidden;

  img {
    // width: 1.2rem !important;
    // height: 1.58rem !important;
    width: 100%;
    // max-width: 100%;
    max-height: 100%;
  }
}

::v-deep .swiper-wrapper,
.swiper-content .swiper-slide .swiper-slide-visible {
  // height: 1.58rem !important;
}

::v-deep .swiper-button-prev,
::v-deep .swiper-rtl .swiper-button-next,
::v-deep .swiper-button-next,
::v-deep .swiper-rtl .swiper-button-prev {
  width: 0.32rem;
  height: 0.32rem;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 0.16rem;
  top: 0.94rem !important;
}

::v-deep .swiper-button-next:after,
::v-deep .swiper-button-prev:after,
::v-deep .swiper-button-next:after,
::v-deep .swiper-button-prev:after {
  color: #ffffff;
  font-size: 0.16rem;
}
</style>
