<template>
  <div class="banner">
    <swiper
      :modules="modules"
      :loop="true"
      :slides-per-view="1"
      :space-between="50"
      :autoplay="{ delay: 4000, disableOnInteraction: false }"
      :scrollbar="{ draggable: false }"
      class="swiperBox"
      @slideChange="onSlideChange"
      :pagination="{ clickable: true }"
    >
      <swiper-slide v-for="(item, index) in dataList" :index="index">
        <a :href="item.url" target="_blank"> <img :src="item.image" /></a>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script setup>
import { Swiper, SwiperSlide } from "swiper/vue";

// 引入swiper样式（按需导入）
import "swiper/css/pagination"; // 轮播图底面的小圆点
// import 'swiper/css/navigation' // 轮播图两边的左右箭头
import { Autoplay, Pagination, Scrollbar } from "swiper";
const props = defineProps({
  dataList: {
    type: Array,
    default: false,
  },
});
// 在modules加入要使用的模块
const modules = [Autoplay, Pagination, Scrollbar];
</script>
<style lang="scss" scoped>
.banner {
  // height: 400px;
  overflow: hidden;
  border-radius: 16px;
  img {
    max-height: 400px;
    width: 100%;
    overflow: hidden;
  }
}
::v-deep .swiper-pagination {
  padding-right: 40px;
  text-align: right;
  z-index: 998;
}
::v-deep .swiper-pagination-bullet {
  width: 32px;
  height: 8px;
  background: var(--bkg-color);
  border-radius: 4px;
  opacity: 1;
}
::v-deep .swiper-pagination-bullet-active {
  background: #123f8d;
}
</style>
