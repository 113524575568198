<template>
  <div class="data-item">
    <div class="card-top">
      <!-- img -->
      <el-image
        class="card-img-size"
        :src="list.icon ? list.icon : src"
        fit="cover"
      />
      <div class="card-info">
        <div>
          <h2>{{ list.userName ? list.userName : list.companyName }} </h2>
          <span>{{ list.operatingPost }}</span>
        </div>
        <div class="">{{ list.intro }}</div>
        <div class="operate">
          <div class="l-info">
            <i class="iconfont">&#xe8c4;</i> <span>{{ list.vcard_time }}</span> <br />
          <i class="iconfont">&#xe63f;</i> <span>{{ list.cityName + list.districtName }}</span>
          </div>
          <div class="r-info">
            <div
        :class="{ 'collect-on': list.isCollect, collect: true }"
        @click="collectClick(list)"
      >
        <i class="iconfont">&#xe8c6;</i> <span>收藏</span>
      </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-bottom">
      <div class="address"></div>
      
    </div>
    <!-- <div class="tag">个人</div> -->
  </div>
</template>

<script setup>
import { ref,  defineProps,defineEmits } from "vue";
let src = ref(require("@/assets/img/ai08.png"));
const emit = defineEmits(["change-tagList"]);
import { socialCollection } from "@/api/ai";
const props = defineProps({
  list: {
    type: Object,
    default: {},
  },
});
async function collectClick(list) {
  await socialCollection({ uid: list.uid, cid: list.cid, type: 3 });
  emit("change-activeList", list);
}

</script>
<style lang="scss" scoped>
.data-item {
  padding: 10px;
  width: 100%;
  height: auto;
  background: var(--bkg-color);
  margin: 0 15px 15px 0;
  .tag {
    position: absolute;
    top: 2px;
    right: -30px;
    height: 25px;
    line-height: 25px;
    width: 90px;
    background: #0070ec;
    color: #fff;
    font-size: 12px;
    transform: rotate(45deg);
    text-align: center;
  }
  .card-top {
    display: flex;
  }
  .card-img-size {
    width: 100px;
    height: 100px;
    border-radius: 8px;
    margin-right: 10px;
  }
  .card-info{
    flex: 1;
    .operate{
      display: flex;
      align-items: center;
    }
    .l-info{
      flex: 1;
    }
    .r-info{
      width: 60px;
      .collect-on {
        cursor: pointer;
      i {
        color: #ffc220;
      }
    }
    }
  }
  .card-info > div:nth-child(1) {
    overflow: hidden;
    & > h2 {
      font-size: 20px;
      font-weight: 500;
      color: var(--text-color);
      float: left;
      line-height: 30px;
    }
    & > span {
      font-size: 14px;
      font-weight: 400;
      color: var(--text-color);
      line-height: 30px;
      margin-left: 10px;
    }
  }
  .card-info > div:nth-child(2) {
    margin: 8px 0 16px 0;
    font-size: 16px;
    font-weight: 400;
    color: var(--text-color);
    line-height: 24px;
  }
  .card-info > div:nth-child(3) {
    & > i {
      font-size: 12px;
    }
    & > span {
      font-size: 14px;
      font-weight: 400;
      color: var(--text-color);
      line-height: 22px;
      vertical-align: middle;
    }
  }
}
</style>
