<template>
  <div class="swiper-content">
    <swiper
      :modules="modules"
      :slidesPerView="4"
      :centeredSlides="flase"
      :spaceBetween="20"
      :navigation="true"
      :virtual="true"
      class="mySwiper"
      @swiper="setSwiperRef"
    >
      <swiper-slide
        v-for="(item, index) in dataList"
        :index="index"
        :key="index"
        style="position: relative"
      >
        <!-- <el-image class="imgClick" :src="item.videoImg"> </el-image> -->
        <video
          class="video-dialog"
          :src="item.link"
          @click="videoClick(item)"
        />
        <i class="iconfonts video-icon" @click="videoClick(item)">&#xe610;</i>
        <div class="video-title">{{ item.title }}</div>
        <!-- <img class="imgClick" @click="handleImage(item, index)" :src="item"/> -->
      </swiper-slide>
    </swiper>
  </div>
  <el-dialog
    v-model="visible"
    width="840px"
    :title="videoData.title"
    :before-close="handleClose"
  >
    <video :src="videoData.link" autoplay controls width="800" />
  </el-dialog>
</template>

<script setup>
// 引入swiper样式（按需导入）
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/virtual";
import { ref, onMounted, reactive, toRaw } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination, Navigation, Virtual } from "swiper";
let swiperRef = ref(null);
// 在modules加入要使用的模块
const modules = [Pagination, Navigation, Virtual];
const setSwiperRef = (swiper) => {
  swiperRef.value = swiper;
};
const props = defineProps({
  dataList: {
    type: Array,
    default: false,
  },
});
const slideTo = (index) => {
  swiperRef.value.slideTo(index - 1, 0);
};
onMounted(() => {
  slideTo(3);
});

let visible = ref(false);
let videoData = ref("");
// 视频弹框
function videoClick(item) {
  videoData.value = item;
  visible.value = true;
}
function handleClose() {
  videoData.value = "";
  visible.value = false;
}
</script>
<style lang="scss" scoped>
.video-dialog {
  width: 100%;
  height: 153px;
  cursor: pointer;
}
.video-title {
  text-align: center;
}
.imgClick {
  cursor: pointer;
  width: 100%;
  height: 153px;
  // object-fit: cover;
}
.video-icon {
  font-size: 55px !important;
  cursor: pointer;
  display: inline-block;
  width: 55px;
  height: 55px;
  position: absolute;
  top: 35px;
  left: 106px;
  right: 0;
  bottom: 0;
}
.swiper-content {
  width: 100%;
}
.swiper-content .swiper-slide {
  width: 320px;
  height: 160px;
  overflow: hidden;
  img {
    width: 100%;
  }
}
::v-deep .swiper-button-prev,
::v-deep .swiper-rtl .swiper-button-next,
::v-deep .swiper-button-next,
::v-deep .swiper-rtl .swiper-button-prev {
  width: 64px;
  height: 64px;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 32px;
  top: 37% !important;
}
// ::v-deep.swiper-button-next,
// .swiper-button-prev :after {
//   top: 37% !important;
// }
::v-deep .swiper-button-next:after,
::v-deep .swiper-button-prev:after,
::v-deep .swiper-button-next:after,
::v-deep .swiper-button-prev:after {
  color: #ffffff;
  font-size: 26px;
  top: 37% !important;
}
</style>
