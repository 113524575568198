<template>
  <div class="data-item" :style="{ width }" @click="dialogDetail(list)">
    <div class="card-top">
      <!-- img -->
      <el-image
        class="card-img-size"
        :src="list.icon ? baseUrl + list.icon : src"
        fit="cover"
      />
      <div class="card-info">
        <div>
          <h2>
            {{ list.userName ? list.userName : list.companyName }}
          </h2>
          <span>{{ list.operatingPost }}</span>
        </div>
        <div class="">{{ list.intro }}</div>
        <div>
          <i class="iconfont">&#xe8c4;</i> <span>{{ list.vcard_time }}</span>
        </div>
      </div>
    </div>
    <div class="card-bottom">
      <div class="address">{{ list.cityName + list.districtName }}</div>
      <div
        class="collect"
        :class="{ 'collect-on': list.isCollect }"
        @click.stop="collectClick(list)"
      >
        <i class="iconfont" title="收藏">&#xe8c6;</i> <span>收藏</span>
      </div>
    </div>
    <div class="tag" :class="[list.cid ? 'corporation-tag' : '']">
      {{ list.cid ? "公司" : "个人" }}
    </div>
  </div>
</template>

<script setup>
import { ref, defineEmits, defineProps } from "vue";
import { socialCollection } from "@/api/ai";
let src = ref(require("@/assets/img/ai08.png"));
let baseUrl = ref(process.env.VUE_APP_BASE_URI + "/media/");
const emit = defineEmits(["change-tagList", "dailog-value"]);

const props = defineProps({
  list: {
    type: Object,
    default: {},
  },
  width: {
    type: String,
    default: "390px",
  },
});
async function collectClick(list) {
  await socialCollection({ uid: list.uid, cid: list.cid, type: 3 });
  emit("change-activeList", list);
}
function dialogDetail(list) {
  emit("dailog-value", list);
}
</script>
<style lang="scss" scoped>
.data-item:nth-child(3n) {
  margin: 0 0px 15px 0;
}
.data-item {
  padding: 10px;
  // width: 390px;
  height: 154px;
  background: var(--anchor-color);
  border-radius: 16px;
  position: relative;
  overflow: hidden;
  float: left;
  margin: 0 15px 15px 0;
  cursor: pointer;
  .tag {
    position: absolute;
    top: 2px;
    right: -30px;
    height: 25px;
    line-height: 25px;
    width: 90px;
    background: #0070ec;
    color: #fff;
    font-size: 12px;
    transform: rotate(45deg);
    text-align: center;
  }
  .corporation-tag {
    background: #12408e;
  }
  .card-top {
    display: flex;
  }
  .card-img-size {
    width: 100px;
    height: 100px;
    border-radius: 8px;
    margin-right: 10px;
  }
  .card-info > div:nth-child(1) {
    overflow: hidden;
    & > h2 {
      font-size: 20px;
      font-weight: 500;
      color: var(--text-color);
      float: left;
      line-height: 30px;
      cursor: pointer;
      max-width: 250px;
      word-break: keep-all;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    & > span {
      font-size: 14px;
      font-weight: 400;
      color: var(--text-color);
      line-height: 30px;
      margin-left: 10px;
    }
  }
  .card-info > div:nth-child(2) {
    margin: 8px 0 16px 0;
    font-size: 16px;
    font-weight: 400;
    color: var(--text-color);
    line-height: 24px;
    width: 260px;
    word-break: keep-all;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .card-info > div:nth-child(3) {
    & > i {
      font-size: 12px;
    }
    & > span {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: var(--text-color);
      line-height: 22px;
      vertical-align: middle;
    }
  }
  .card-bottom {
    margin-top: 8px;
    display: flex;
    .address {
      flex: 1;
      font-size: 14px;
      font-weight: 400;
      color: var(--text-color);
      line-height: 22px;
    }
    .collect {
      cursor: pointer;
      & > i {
        font-size: 14px;
        color: var(--text-color);
      }
      & > span {
        font-size: 12px;
        font-weight: 400;
        color: var(--text-color);
        line-height: 18px;
        vertical-align: middle;
      }
    }
    .collect-on {
      i {
        color: #ffc220;
      }
    }
  }
}
</style>
