<template>
  <div :class="[!adaptiveStore.isShowView ? 'app-show' : 'pc-show']">
    <div :class="{box: applyItem.isFree === 1}" class="box-item">
      <el-tooltip popper-class="tooltip-width" effect="dark" :content="applyItem.intro" placement="bottom">
        <div class="apply-box" @click.stop="skipChange" ref="buttonRef">
          <div class="del-icon" v-if="delApp">
            <i @click.stop="delClick" class="iconfont">&#xe616;</i>
          </div>
          <div class="state-icon" v-if="!delApp">
            <img v-if="applyItem.isVipApp" src="@/assets/img/state3.png" />
            <img v-else-if="applyItem.isRecommend" src="@/assets/img/state4.png" />
            <img v-else-if="applyItem.isChoiceness" src="@/assets/img/state2.png" />
            <img v-else-if="applyItem.isDirect" src="@/assets/img/state1.png" />
            <img v-else-if="applyItem.isFree" src="@/assets/img/state5.png" />
          </div>
          <div class="img-box">
            <el-image class="img-box" :src="applyItem.icon" :fit="contain" />
          </div>
          <div class="apply-message">
            <h4 :title="applyItem.appName">{{ applyItem.appName }}</h4>
            <div>{{ applyItem.intro }}</div>
          </div>
        </div>
      </el-tooltip>
    </div>
  </div>
</template>

<script setup>
import router from "@/router";
import { watch, defineEmits, ref, onMounted } from "vue";
const emit = defineEmits(["del-app"]);
const buttonRef = ref();
const popoverRef = ref();
import useDigitalman from "@/store/adaptive";
const adaptiveStore = useDigitalman();
const props = defineProps({
  applyItem: {
    type: Object,
    default: {},
  },
  delApp: {
    type: Boolean,
    default: false,
  },
});
// 跳转
function skipChange() {
  router.push({
    path: "/application/detail",
    query: { id: props.applyItem.id || props.applyItem.appId },
  });
}
// 删除
function delClick() {
  emit("del-app", { appId: props.applyItem.id, trialType: 2 });
}
</script>

<style>
.tooltip-width {
  max-width: 300px !important;
}
</style>

<style lang="scss" scoped>
.box {
  position: relative;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

}

.box::before {
  content: "";
  width: 500px;
  height: 500px;
  position: absolute;
  background-image: conic-gradient(transparent, #21d4fd, #b721ff, transparent 50%);
  animation: rotate 3.5s linear infinite;
}

.box:hover {
  // background: #000;
  // transform: translateY(-10px);
  animation: moveUp 0.2s forwards linear;
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0.19), 0 4px 5px rgba(0, 0, 0, 0.23);
}

.box-item {
  border-radius: 8px;
  margin-bottom: 10px;
  padding: 4px;
  
  background-color: #fff;
}

::v-deep .el-popper {
  max-width: 40% !important;
  // overflow: auto;
}

.pc-show {
  min-width: initial !important;

  /* .apply-box:hover {
    // background: #000;
    // transform: translateY(-10px);
    animation: moveUp 0.2s forwards linear;
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0.19), 0 4px 5px rgba(0, 0, 0, 0.23);
  } */

  /* 定义动画 */
  @keyframes moveUp {
    0% {
      transform: translateY(0);
    }

    100% {
      transform: translateY(-8px);
    }
  }

  .apply-box {
    position: relative;
    cursor: pointer;
    height: 64px;
    width: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    overflow: hidden;
    border-radius: 8px;
    background: var(--anchor-color);
    // background: #ffffff;
    /* margin-bottom: 10px; */

    .state-icon {
      position: absolute;
      right: 0px;
      top: 0px;

      img {
        width: 32px;
        height: 32px;
      }
    }

    .del-icon {
      position: absolute;
      right: 1px;
      top: -4px;

      i {
        color: red;
        font-size: 22px;
      }
    }

    .img-box {
      width: 44px;
      height: 44px;
      border-radius: 4px;
    }

    .apply-message {
      margin-left: 10px;
      overflow: hidden;

      h4 {
        font-size: 16px;
        // color: #272727;
        color: var(--text-color);
        line-height: 24px;
        overflow: hidden;
        /* 隐藏溢出的内容 */
        text-overflow: ellipsis;
        /* 当内容溢出时显示省略号 */
        -webkit-line-clamp: 1;
        /* 这里的数字表示显示的行数，2表示两行 */
        display: -webkit-box;
        /* 显示省略号 */
        -webkit-box-orient: vertical;
        /* 垂直显示内容 */
        overflow: hidden;
        /* 隐藏超出部分 */
      }

      div {
        font-size: 12px;
        color: var(--text-ubtitle-color);
        // color: rgba(0, 0, 0, 0.4);
        line-height: 18px;
        overflow: hidden;
        /* 隐藏溢出的内容 */
        text-overflow: ellipsis;
        /* 当内容溢出时显示省略号 */
        -webkit-line-clamp: 1;
        /* 这里的数字表示显示的行数，2表示两行 */
        display: -webkit-box;
        /* 显示省略号 */
        -webkit-box-orient: vertical;
        /* 垂直显示内容 */
        overflow: hidden;
        /* 隐藏超出部分 */
      }
    }
  }
}

// 移动端
.app-show {
  padding: 0 !important;

  .apply-box {
    position: relative;
    cursor: pointer;
    height: 0.65rem;
    width: 100%;
    padding: 0.1rem;
    display: flex;
    align-items: center;
    overflow: hidden;
    border-radius: 0.08rem;
    background: var(--anchor-color);
    /* margin-bottom: 0.1rem; */

    .state-icon {
      position: absolute;
      right: 0;
      top: -0.83rem;

      img {
        width: 0.32rem;
        height: 0.32rem;
      }
    }

    .del-icon {
      position: absolute;
      right: 0.01rem;
      top: -0.79rem;

      i {
        color: red;
        font-size: 0.2rem;
      }
    }

    .img-box {
      width: 0.44rem;
      height: 0.44rem;
      border-radius: 0.04rem;
      overflow: initial !important;
    }

    .apply-message {
      margin-left: 0.1rem;

      h4 {
        font-size: 0.16rem;
        // color: #272727;
        color: var(--text-color);
        line-height: 0.24rem;
        overflow: hidden;
        /* 隐藏溢出的内容 */
        text-overflow: ellipsis;
        /* 当内容溢出时显示省略号 */
        -webkit-line-clamp: 1;
        /* 这里的数字表示显示的行数，2表示两行 */
        display: -webkit-box;
        /* 显示省略号 */
        -webkit-box-orient: vertical;
        /* 垂直显示内容 */
        overflow: hidden;
        /* 隐藏超出部分 */
      }

      div {
        font-size: 12px;
        color: var(--text-ubtitle-color);
        line-height: 0.18rem;
        overflow: hidden;
        /* 隐藏溢出的内容 */
        text-overflow: ellipsis;
        /* 当内容溢出时显示省略号 */
        -webkit-line-clamp: 1;
        /* 这里的数字表示显示的行数，2表示两行 */
        display: -webkit-box;
        /* 显示省略号 */
        -webkit-box-orient: vertical;
        /* 垂直显示内容 */
        overflow: hidden;
        /* 隐藏超出部分 */
      }
    }
  }
}
</style>
