<template>
  <div class="card" @click.stop="goNews">
    <div class="image">
      <img :src="baseUrl + docItem.cover" alt="">
    </div>
    <div class="content">
      <a href="#">
        <span class="title one-apostrophe">
          {{ docItem.title }}
        </span>
      </a>

      <p class="desc two-apostrophe">
        {{ docItem.description }}
      </p>
    </div>
  </div>
</template>
<script setup>
import { ref } from 'vue';

const props = defineProps({
  docItem: {
    type: Object,
    default: () => ({})
  }
})
let baseUrl = ref(process.env.VUE_APP_BASE_URI);
const goNews = () => {
  // window.open(props.docItem.documentUrl)
  window.location.href = props.docItem.documentUrl
}
</script>
<style scoped lang="scss">
.card {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;

  .image {
    width: 100%;
    height: 150px;
    overflow: hidden;

    img {
      width: 100%;
      height: 150px;
      object-fit: cover;
    }
  }

  .content {
    padding: 0.05rem;
    font-size: 0.12rem;
    background-color: #fff;

    .title {
      font-size: 0.15rem;
      line-height: 1;
      font-weight: 600;
    }
  }
}
</style>