<template>
  <el-card class="publish-item" style="max-height: 250px; overflow: hidden">
    <div class="tagItem">
      <el-tag :type="tagInfo.type" v-if="type === 1">{{ tagInfo.name }}</el-tag>
    </div>
    <div class="content">
      <div class="avatar" v-if="type === 1">
        <img
          v-if="item.userInfo && item.userInfo.icon"
          :src="baseUrl + item.userInfo.icon"
          alt=""
        />
        <img
          v-else
          src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"
          alt=""
        />
      </div>
      <div class="info">
        <!-- <div class="name">{{ item.name }}</div> -->
        <div
          class="pulish-title hide-over-1"
          v-if="type === 1"
          @click="goPostDetail(item, 1)"
        >
          {{ item.title }}
        </div>
        <div
          class="pulish-title hide-over-1"
          v-if="type === 2"
          @click="goPostDetail(item, 2)"
        >
          {{ item.postTitle }}
        </div>
        <div class="pulish-content hide-over-2" v-html="item.content"></div>
        <div
          class="opts"
          :style="{ justifyContent: type === 1 ? 'space-between' : 'flex-end' }"
        >
          <div class="left" v-if="type === 1">
            <div class="item">
              <div class="time">
                {{ item.created_at }}
              </div>
            </div>
            <div class="item">
              <i class="iconfont">&#xe661;</i>
              <span>{{ item.hits }} 看过</span>
            </div>
            <div class="item">
              <i class="iconfont">&#xe63d;</i>
              <span>{{ item.comments }} 评论</span>
            </div>
            <div class="item">
              <i class="iconfont">&#xe63c;</i>
              <span>{{ item.praise }} 点赞</span>
            </div>
          </div>
          <div class="right">
            <el-button type="primary" link size="small" @click="edit(item)"
              >编辑</el-button
            >
            <el-button
              type="danger"
              link
              size="small"
              @click="$emit('delete', item.id)"
              >删除</el-button
            >
          </div>
        </div>
      </div>
    </div>
  </el-card>
  <ReplyComment
    @submit-reply="submitReply"
    ref="replyCommentRef"
  ></ReplyComment>
</template>
<script setup>
import { fetchEditComment } from "@/api/ai";
import { ReplyComment } from "@/components/index";
import { ElMessage } from "element-plus";
import { ref, computed } from "vue";
import { useRouter } from "vue-router";

const emit = defineEmits(["refresh"]);

const router = useRouter();

const props = defineProps({
  item: {
    type: Object,
    default: () => ({}),
  },
  type: {
    type: Number,
    default: 1,
  },
  status: {
    type: Number,
    default: 1,
  },
});
let baseUrl = ref(process.env.VUE_APP_BASE_URI + "/media/");

const tagInfo = computed(() => {
  switch (props.status) {
    case 1:
      return {
        name: "审核中",
        type: "info",
      };
    case 2:
      return {
        name: "已发布",
        type: "success",
      };
    case 3:
      return {
        name: "未通过",
        type: "danger",
      };
    default:
      return {
        name: "审核中",
        type: "info",
      };
  }
});

// 帖子详情
const goPostDetail = (item, type) => {
  let paramsId;
  if (type === 1) {
    paramsId = item.id;
  } else if (type === 2) {
    paramsId = item.postId;
  }
  router.push({
    path: `/commonet/detail/${paramsId}`,
    query: {
      type: "topic",
    },
  });
};

const submitData = ref({});
const replyCommentRef = ref();
// 编辑
const edit = (item) => {
  switch (props.type) {
    // 主题，帖子
    case 1:
      // 跳转到发帖页面，回显内容
      router.push({
        path: `/commonet/new-commont/${item.topic.id}`,
        query: {
          type: "edit",
          postId: item.id,
        },
      });
      break;
    // 回复
    case 2:
      const openData = {
        content: item.content,
        title: "编辑评论",
      };
      replyCommentRef.value.open(openData);
      submitData.value.commentId = item.id;
      break;
    default:
      break;
  }
};

// 回复评论
const submitReply = async (content) => {
  const params = {
    commentId: submitData.value.commentId,
    content: content,
  };
  const { data: res } = await fetchEditComment(params);
  if (res.code === 0) {
    ElMessage.success("编辑成功");
    submitData.value.commentId = "";
    emit("refresh");
  }
};
</script>
<style scoped lang="scss">
.tagItem {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.publish-item {
  position: relative;
  margin-bottom: 10px;
  max-height: 220px;

  .content {
    display: flex;
    height: 100%;

    .avatar {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 10px;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    .info {
      flex: 1;
      display: flex;
      flex-direction: column;

      .pulish-title {
        cursor: pointer;
      }

      .pulish {
        &-title {
          height: 30px;
          line-height: 30px;
          font-size: 18px;
          font-weight: 600;
          margin-bottom: 10px;
        }

        &-content {
          flex: 1;
          overflow: hidden;
          margin-bottom: 10px;
          max-height: 120px;
          max-width: 1050px;

          img {
            width: 50px !important;
            height: 50px !important;
            object-fit: cover;
            margin-right: 10px;
          }
        }

        .opts {
          height: 50px;
          line-height: 50px;
        }
      }
    }

    .opts {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .left {
        display: flex;
        align-items: center;

        .item {
          color: #999;
          font-size: 12px;

          .iconfont {
            margin-right: 3px;
          }

          cursor: pointer;
          margin-right: 22px;
          display: flex;
          align-items: center;
          line-height: 20px;
        }
      }

      .right {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }
  }
}
</style>
