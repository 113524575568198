<template>
  <div class="app-banner">
    <swiper
      :modules="modules"
      :loop="true"
      :slides-per-view="1"
      :space-between="50"
      :autoplay="{ delay: 4000, disableOnInteraction: false }"
      :pagination="{ clickable: true }"
      :scrollbar="{ draggable: false }"
      class="swiperBox"
      @slideChange="onSlideChange"
    >
      <swiper-slide v-for="(item, index) in dataList" :index="index">
        <a :href="item.url" target="_blank"> <img :src="item.image" /></a>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script setup>
import { Swiper, SwiperSlide } from "swiper/vue";

// 引入swiper样式（按需导入）
import "swiper/css/pagination"; // 轮播图底面的小圆点
// import 'swiper/css/navigation' // 轮播图两边的左右箭头
import { Autoplay, Pagination, Scrollbar } from "swiper";
const props = defineProps({
  dataList: {
    type: Array,
    default: false,
  },
});
// 在modules加入要使用的模块
const modules = [Autoplay, Pagination, Scrollbar];
</script>
<style lang="scss" scoped>
a {
  display: flex;
}
.app-banner {
  // height: 1.29rem;
  // margin-top: 0.08rem;
  margin: 0 -0.15rem;
  overflow: hidden;
  // border-radius: 0.2rem;
  img {
    height: 1.3rem;
    width: 100%;
  }
}
::v-deep .swiper-pagination {
  padding-right: 0.1rem;
  text-align: right;
  z-index: 998;
}
::v-deep .swiper-pagination-bullet {
  width: 0.1rem;
  height: 0.04rem;
  background: var(--bkg-color);
  border-radius: 0.02rem;
  opacity: 1;
}
::v-deep .swiper-pagination-bullet-active {
  background: #123f8d;
}

::v-deep .swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: -0.36rem !important;
}
</style>
