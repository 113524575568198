<template>
  <div class="app-show-state">
    <ul class="pandant-box">
      <li @click="appPendant">
        <!-- <i v-if="screen" class="iconfont">&#xe60e;</i> -->
        <i class="iconfont">&#xe612;</i>
      </li>
      <li>
        <van-search @click-input="goSearch" v-if="$route.path != '/news'"  placeholder="搜索" />
        <van-search @search="onSearch" v-else v-model="keyword"  placeholder="搜索" />
        <!-- <el-input
          v-else
          v-show="!screen"
          class="search"
          prefix-icon="Search"
          @keyup.enter.native="onSearch"
          @clear="onSearch"
          v-model="keyword"
          clearable
          placeholder="搜索"
        ></el-input> -->
        <!-- <i class="iconfont">&#xe612;</i> -->
      </li>
      <li @click="screenClick">
        <!-- <router-link to="/activity"
          ><i class="iconfont">&#xec8d;</i></router-link
        > -->
        <i class="iconfont">&#xec8d;</i>
      </li>
    </ul>

    <!-- <div class="app-popup-flex">
      <i class="iconfont" @click="scrollLeft">&#xe762;</i>
      <van-tabs
        title-class="app-popup"
        v-model:active="active"
        line-height="0"
        scrollspy
        sticky
      >
        <van-tab>
          <template #title
            ><router-link to="/index">
              <i class="iconfont" v-html="`&#xe65c;`"></i><span>首页</span>
            </router-link></template
          >
        </van-tab>
        <van-tab>
          <template #title
            ><router-link to="/news">
              <i class="iconfont">&#xe78d;</i><span>资讯</span>
            </router-link></template
          >
        </van-tab>
        <van-tab>
          <template #title
            ><router-link to="/application" @click="screenClick">
              <i class="iconfont">&#xe712;</i><span>应用</span>
            </router-link></template
          >
        </van-tab>
        <van-tab>
          <template #title
            ><router-link to="/connections">
              <i class="iconfont">&#xe704;</i><span>人脉</span>
            </router-link></template
          >
        </van-tab>
        <van-tab>
          <template #title
            ><router-link to="/activity">
              <i class="iconfont">&#xe870;</i><span>活动</span>
            </router-link></template
          >
        </van-tab>
        <van-tab>
          <template #title
            ><router-link to="/train">
              <i class="iconfont">&#xe876;</i><span>教育</span>
            </router-link></template
          >
        </van-tab>
        <van-tab>
          <template #title>
            <router-link to="/personal" v-if="getToken()">
              <i class="iconfont">&#xe72a;</i><span>个人中心</span>
            </router-link>
            <router-link to="/login" v-else>
              <i class="iconfont">&#xe72a;</i><span>个人中心</span>
            </router-link>
          </template>
        </van-tab>
      </van-tabs>
      <i class="iconfont" @click="scrollLeft(1)">&#xe763;</i>
    </div> -->

    <!-- <div class="app-popup-flex">
      <i class="iconfont" @click="scrollLeft">&#xe762;</i>
      <div
        id="list-box"
        class="app-popup-box"
        ref="scrollview"
        @mousewheel="scrollChange"
      >
        <ul class="app-popup" @click="popupClick">
          <li id="list">
            <router-link to="/index">
              <i class="iconfont" v-html="`&#xe65c;`"></i><span>首页</span>
            </router-link>
          </li>
          <li>
            <router-link to="/news"
              ><i class="iconfont">&#xe78d;</i><span>资讯</span></router-link
            >
          </li>
          <li>
            <router-link to="/application"
              ><i class="iconfont">&#xe712;</i><span>应用</span></router-link
            >
          </li>
          <li>
            <router-link to="/connections"
              ><i class="iconfont">&#xe704;</i><span>人脉</span></router-link
            >
          </li>
          <li>
            <router-link to="/activity"
              ><i class="iconfont">&#xe870;</i><span>活动</span></router-link
            >
          </li>
          <li>
            <router-link to="/train"
              ><i class="iconfont">&#xe876;</i><span>教育</span></router-link
            >
          </li>
          <li>
            <router-link to="/personal"
              ><i class="iconfont">&#xe72a;</i
              ><span>个人中心</span></router-link
            >
          </li>
        </ul>
      </div>
      <i class="iconfont">&#xe763;</i>
    </div> -->
    <!-- 菜单导航栏 -->
    <van-popup
      v-model:show="show"
      position="left"
      :overlay-style="{ background: 'rgba(255,255,255,0)' }"
      :style="{ height: 'calc(100vh - 0.84rem)' }"
    >
      <ul class="app-popup" @click="popupClick">
        <div class="app-line"></div>
        <li>
          <router-link to="/web/index">
            <i class="iconfont">&#xe65c;</i><span>首页</span>
          </router-link>
        </li>
        <li>
          <router-link to="/news"
            ><i class="iconfont">&#xe78d;</i><span>资讯</span></router-link
          >
        </li>
        <li>
          <router-link to="/application"
            ><i class="iconfont">&#xe712;</i><span>应用</span></router-link
          >
        </li>
        <li>
          <router-link to="/connections"
            ><i class="iconfont">&#xe704;</i><span>人脉</span></router-link
          >
        </li>
        <li>
          <router-link to="/activity"
            ><i class="iconfont">&#xe870;</i><span>活动</span></router-link
          >
        </li>
        <li>
          <router-link to="/train"
            ><i class="iconfont">&#xe876;</i><span>教育</span></router-link
          >
        </li>
        <li>
          <router-link to="/personal"
            ><i class="iconfont">&#xe72a;</i><span>个人中心</span></router-link
          >
        </li>
      </ul>
    </van-popup>

    <div class="screen" v-show="screen">
      <app-screen @change-tagList="updateTagList"></app-screen>
    </div>
  </div>
</template>

<script setup>
import { getToken } from "@/utils/auth";
// import { getCategory } from "@/api/ai";
import router from "@/router";
import { ref, reactive, onMounted } from "vue";
import { AppScreen } from "@/components";
// import { Popup } from "vant";
// let categoryList = ref();
const route = useRoute()
let keyword = ref();
let show = ref(false);
let screen = ref(false);

let active = ref(0);
onMounted(async () => {
  // let res = await getCategory();
  // categoryList.value = res.data.data.common_category_list.map((item) => {
  //   return {
  //     ...item,
  //     iconUrl: item.iconUrl
  //       ? process.env.VUE_APP_BASE_URI + "/media/" + item.iconUrl
  //       : "",
  //   };
  // });
});

// 左滑动逻辑
function scrollLeft(e) {
  if (e) {
    active.value = active.value + 1;
  } else {
    active.value = active.value - 1;
  }
}
import useDigitalman from "@/store/adaptive";
import { useRoute } from "vue-router";
const adaptiveStore = useDigitalman();
const goSearch = () => {
  if(route.path !== '/news') {
    router.push({
    path: "/appListSearch",
  });
  }
}
function onSearch() {
  adaptiveStore.appSearch = keyword.value;
  if(route.path == '/news') {
    return
  }
  router.push({
    path: "/appListSearch",
  });
  // if (keyword.value) {
  //   router.push({ path: "/application", query: { search: keyword.value } });
  // } else {
  //   router.push("/application");
  // }
}
function updateTagList(type) {
  screen.value = type;
}
// App
function appPendant() {
  if (screen.value) {
    screen.value = !screen.value;
  } else {
    show.value = true;
  }
}
function popupClick() {
  show.value = false;
}
function screenClick() {
  screen.value = true;
}
</script>
<style lang="scss" scoped>
::-webkit-scrollbar {
  width: 0px;
}
.pendant {
  position: fixed;
  left: 50%;
  top: 17%;
  background: var(--anchor-color);
  padding: 10px;
  width: 170px;
  border-radius: 8px;
  margin-left: -730px;
  // margin-left: -54%;
  max-height: 70%;
  display: flex;
  flex-direction: column;
  .search {
    height: 40px;
  }
  .ul-menu {
    flex: 1;
    color: var(--text-color);
    font-size: 16px;
    line-height: 36px;
    margin-top: 10px;
    overflow-y: scroll;
    a {
      display: block;
      border-radius: 8px;
      padding: 6px 5px;
    }
    a.focus,
    a:hover {
      background: var(--min-box-color);
    }
    img {
      width: 24px;
      height: 24px;
      vertical-align: middle;
      margin-right: 3px;
    }
  }
}
.banner {
  height: 400px;
  overflow: hidden;
  border-radius: 16px;
  margin-top: 20px;
  img {
    width: 100%;
  }
}
::v-deep .swiper-pagination {
  padding-right: 40px;
  text-align: right;
  z-index: 998;
}
::v-deep .swiper-pagination-bullet {
  width: 32px;
  height: 8px;
  background: var(--bkg-color);
  border-radius: 4px;
  opacity: 1;
}
::v-deep .swiper-pagination-bullet-active {
  background: #123f8d;
}

::v-deep .van-tab {
  line-height: 0;
}
.van-tabs {
  width: 3rem;
}
.screen {
  position: absolute;
  top: 0;
  overflow-x: hidden;
  // calc(100vh - 0.84rem)
  height: calc(100vh - 0.86rem);
  margin-top: 0.4rem;
  width: 100%;
  background-color: #ffff;
  z-index: 999;
  // padding-right: 0.16rem;
}
.app-show-state {
  // height: 0.4rem;
  // overflow: hidden;
  position: relative;
  // top: 0.44rem;
  // margin: 0 -0.16rem;
  z-index: 998;
  // width: 100%;
  background: #fff;
  .pandant-box {
    // position: fixed;
    width: 100%;
    height: 0.4rem !important;
    z-index: 9;
    display: flex;
    justify-content: space-between;
    align-items: center;
    li {
      display: flex;
    }
    & > li:nth-child(1) {
      // padding: 0 0.1rem;
      padding: 0 0.18rem 0 0.17rem;
      line-height: 0.4rem !important;
      // cursor: pointer;
    }
    & > li:nth-child(2) {
      flex: 1;
      .search {
        height: 0.32rem;
      }
    }

    & > li:nth-child(3) {
      padding: 0 0.18rem 0 0.17rem;
      line-height: 0.4rem !important;
    }
  }

  .app-popup-flex {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    // i {
    //   padding: 0.06rem;
    // }
  }

  .van-tab--grow {
    i {
      vertical-align: inherit !important;
      margin-right: 0.06rem;
      font-size: 0.14rem;
    }
    span {
      font-size: 0.14rem;
    }
  }
  .app-popup {
    // width: 100vh;
    & > li {
      border-bottom: 0.01rem solid #eaeaea;
      width: 100%;
      padding: 0 0.16rem 0 0;
      font-size: 0.16rem;
      i {
        margin: 0.05rem 0.16rem;
        background: #e0e0e0;
        height: 0.4rem;
        width: 0.4rem;
        text-align: center;
        line-height: 0.4rem;
        vertical-align: baseline;
        display: inline-block;
        border-radius: 50%;
      }
      a {
        display: block;
      }
    }
  }
}

.router-link-exact-active {
  color: #297fff;
  display: inline-block;
}
::v-deep .search .el-input__wrapper {
  background-color: #f5f5f5 !important;
  box-shadow: 0 0 0 0.01rem #f5f5f5 !important;
  // box-shadow: 0 0 0 0.01rem var(--input-color);
  // background: var(--input-color);
}
::v-deep .van-popup--left {
  // top: 55.1%;
  // top: 0.84rem;
  transform: initial;
  position: relative;
  z-index: 2001;
}

::v-deep .van-search {
  width: 100%;
  padding:0;
}
</style>
