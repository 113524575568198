<template>
  <div class="pc-show-box" v-if="adaptiveStore.isShowView">
    <!-- src="https://caip.org.cn/ad/" -->
    <!-- <iframe
      v-if="isShowPlacard"
      :src="ifRameSrc"
      class="isPlacard"
      frameborder="0"
      scrolling="no"
    ></iframe> -->
    <!--首屏向下划动:class="{ placard: showPlacard, noPlacard: noShowPlacard }" -->
    <!-- 头部导航栏 -->
    <!-- v-if="showHeader" -->
    <!-- 左边侧边栏 -->
    <!-- <pendant v-if="showPendant"></pendant> -->
    <!-- 右侧边栏 -->
    <!-- <pendant-right v-if="showPendant && !isShowRanking"></pendant-right> -->
    <!-- top100 -->
    <!-- <pendant-right-ranking
      v-if="showPendant && isShowRanking"
    ></pendant-right-ranking> -->
    <header-navigation></header-navigation>
    <!-- 主体icon -->
    <div class="theme" @click="themeClick">
      <div>
        <i v-if="!adaptiveStore.themeState" class="iconfont">&#xe60b;</i>
        <i v-else class="iconfont">&#xe609;</i>
      </div>
    </div>
    <!-- 会员群 -->
    <div class="theme membership-group" @click="wechatGroupVisible = true">
      <div>
        <i class="iconfont">&#xe674;</i>
      </div>
    </div>
    <el-dialog v-model="wechatGroupVisible" title="客服微信" width="30%">
      <el-image style="width: 100%" :src="require('@/assets/img/wechatGroup.png')"></el-image>
    </el-dialog>
    <!-- 首屏右下角视频  -->
    <div class="video-bit" v-if="adaptiveStore.isSignIn">
      <div class="video-header">
        <i class="iconfont pointer" v-if="!isUnfold" @click="isUnfoldClick">&#xe6f4;</i>
        <i class="iconfont pointer" v-if="isUnfold" @click="isUnfoldClick">&#xe6f4;</i>
        <div class="title" @click="toggleClick">
          <i data-id="1" class="iconfont">&#xe633;</i>
          {{ videoList[videoIndex].name }}
          <i data-id="2" class="iconfont">&#xe634;</i>
        </div>
        <div class="pointer" @click="delClick">X</div>
      </div>
      <div v-if="isUnfold">
        <video width="400" autoplay controls="controls" :src="videoList[videoIndex].url"></video>
      </div>
    </div>
    <div class="common-layout">
      <el-container>
        <div style="background: var(--anchor-color)">
          <side-menu v-if="isInlayPage"></side-menu>
        </div>
        <el-container>
          <router-view />
        </el-container>
      </el-container>
    </div>
    <!-- 简介 -->
    <company-profile v-if="isInlayPage"></company-profile>
    <footer-navigation></footer-navigation>
  </div>
  <!-- 移动端 -->
  <div class="app-show-box" v-if="!adaptiveStore.isShowView">
    <header class="header" v-if="!adaptiveStore.isShowView">
      {{ title }}
      <div class="logo">
        <router-link to="/web/index" v-if="appLogoShow">
          <img src="@/assets/img/appLogo.png" />
        </router-link>
        <a @click="goBack" v-else>
          <img src="@/assets/img/leftArrow.png" />
        </a>
      </div>
    </header>
    <app-pendant class="top-sticky" v-if="appLogoShow"></app-pendant>
    <router-view class="app-view" :class="{ appLogin: appShowPendant }">
    </router-view>
    <!-- 下载app -->
    <!-- <div class="tooltip">
      <FloatingBubble
        style="background: #fafafa;opacity: 0.8; width: 0.3rem; height: 0.3rem; color: #000; font-size: 0.12rem;"
        icon="down" @click="goAppDownload" />
    </div> -->
    <company-profile v-if="showPendant"></company-profile>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, reactive, onBeforeUnmount } from "vue";
import { useRouter } from "vue-router";
import {
  Pendant,
  PendantRight,
  AppPendant,
  PendantRightRanking,
  HeaderNavigation,
  CompanyProfile,
  SideMenu,
  FooterNavigation,
} from "@/components";
import { FloatingBubble } from 'vant';
import LocalCache from "@/plugins/cache";
const router = useRouter();
// 星河（飞浆）
let isInlayPage = ref(true);
// 视频
let isUnfold = ref(true);
// 排行
let isShowRanking = ref(false);
let showPendant = ref(false);
let showHeader = ref(false);
let showPlacard = ref(false);
let noShowPlacard = ref(false);
// 首屏
let isShowPlacard = ref(true);
let oldScrollTop = ref(0);
let ifRameSrc = ref("");
let title = ref("");
let appShowPendant = ref(false);
let appLogoShow = ref(false);
let LogoIcon = reactive([
  "/web/index",
  "/web/index/",
  "/news",
  "/application",
  "/connections",
  "/activity",
  "/train",
  "/personal",
]);
import {
  getHomeList,
  getUpgradeMembership,
  getPayStatus,
  getRecommend,
} from "@/api/ai";
import { ElMessage } from "element-plus";
import useDigitalman from "@/store/adaptive";
const adaptiveStore = useDigitalman();
watch(
  () => router.currentRoute.value,
  async (newValue) => {
    if (!adaptiveStore.appRecommendList) {
      const res = await getRecommend();
      adaptiveStore.appRecommendList = res.data.data;
    }
    if (JSON.stringify(newValue.query) !== "{}") {
      adaptiveStore.query = newValue.query;
    }
    if (newValue.path == "/application") {
      // 应用轮播图、排行
      getHomeList().then((res) => {
        adaptiveStore.bannerArr = res.data.data.swiper;
        adaptiveStore.rankList = res.data.data.rankList;
        isShowRanking.value = true;
      });
    } else {
      isShowRanking.value = false;
    }
    var clientWidth =
      document.documentElement.clientWidth || document.body.clientWidth;
    adaptiveStore.isShowView = clientWidth >= 768;
    title.value = newValue.meta.title;
    appLogoShow.value = LogoIcon.indexOf(newValue.path) !== -1 ? true : false;
    if (newValue.path == "/login" || newValue.path == "/agreement") {
      showPendant.value = false;
      appShowPendant.value = false;
    } else {
      showPendant.value = true;
      appShowPendant.value = true;
    }
    if (newValue.path == "/web/index" || newValue.path == "/web/index/") {
      // showPendant.value = false;
      showHeader.value = false;
      isShowPlacard.value = true; //首屏
      ifRameSrc.value = window.location.origin + "/ad/";
    } else if (newValue.path == '/personal/income' || newValue.path == '/personal/promotionRecord' || newValue.path == '/personal/explain') {
      showPendant.value = false;
    } else {
      isShowPlacard.value = false; //首屏
      showHeader.value = true;
    }
    // 星河（飞浆）页面
    if (newValue.path == "/inlayPage") {
      isInlayPage.value = false;
    } else {
      isInlayPage.value = true;
    }
  },
  { immediate: true }
);

// 弹框视频
let videoIndex = ref(0);
let videoList = ref([
  { name: "网站介绍", url: require("@/assets/media/WebsiteIntroduction.mp4") },
  {
    name: "操作指引",
    url: require("@/assets/media/OperatingInstructions.mp4"),
  },
]);
function delClick() {
  adaptiveStore.isSignIn = false;
}
function toggleClick(e) {
  if (e.target.dataset.id === "1") {
    if (videoIndex.value == 0) return;
    videoIndex.value--;
  } else {
    if (videoIndex.value >= videoList.value.length - 1) return;
    videoIndex.value++;
  }
}

// 改变主题颜色
let themeState = ref(false);
function themeClick() {
  adaptiveStore.themeState = !adaptiveStore.themeState;
  if (adaptiveStore.themeState) {
    window.document.documentElement.setAttribute("data-theme", "dark");
  } else {
    window.document.documentElement.setAttribute("data-theme", "light");
  }
}
// 微信群
let wechatGroupVisible = ref(false);
onMounted(async () => {
  // if (/(iPad|iPod|Mac)/i.test(navigator.userAgent)) {
  //   document.body.style.zoom = 0.9;
  // } else {
  //   document.body.style.zoom = 1;
  // }
  // window.addEventListener("scroll", () => {
  //   let scrollTop =
  //     window.pageYOffset ||
  //     document.documentElement.scrollTop ||
  //     document.body.scrollTop;
  //   // 一屏高度
  //   var clientHeight =
  //     document.documentElement.clientHeight || document.body.clientHeight;
  //   let path = window.location.pathname;
  //   if (path === "/web/index" || path === "/web/index/") {
  //     if (scrollTop <= clientHeight - clientHeight / 6) {
  //       showPendant.value = false;
  //     } else {
  //       showPendant.value = true;
  //     }
  //   }
  // });
});
// 视频展开缩放
function isUnfoldClick() {
  isUnfold.value = !isUnfold.value;
}
// 防抖
function debounce(fn, wait) {
  var timeout = null;
  return function () {
    if (timeout !== null) clearTimeout(timeout);
    timeout = setTimeout(fn, wait);
  };
}
// App
function goBack() {
  let newValue = router.currentRoute.value;
  if (newValue.path === "/personal/application") {
    router.replace({ path: "/personal" });
    LocalCache.local.remove("listingData");
  } else if (newValue.path === "/personal/application/listing") {
    router.push("/personal/application");
  } else {
    router.back(-1);
  }
}

// 跳转到下载app
function goAppDownload() {
  router.push("/download");
}
</script>

<style lang="scss" scoped>
// @media screen and (min-width: 0px) and (max-width: 768px) {
//   .pc-show-box {
//     display: none !important;
//   }
//   .app-show-box {
//     display: block !important;
//   }
// }
// @media screen and (min-width: 769px) and (max-width: 3840px) {
//   .app-show-box {
//     display: none !important;
//   }
//   .pc-show-box {
//     display: block !important;
//   }
// }
// .el-image__placeholder {
//   // background: url("~@/assets/img/loading.gif") no-repeat 50% 50%;
//   background: url("../assets/img/loading.gif") no-repeat 50% 50%;
//   background-size: 50%;
//   width: 50vw;
//   height: 200px;
// }


.video-bit {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 400px;
  background: var(--anchor-color);

  border-radius: 5px;
  z-index: 999;

  .title {
    i {
      vertical-align: baseline;
      font-size: 14px;
      cursor: pointer;
    }
  }

  .video-header {
    display: flex;
    justify-content: space-between;
    // color: #000;
    color: #666;
    font-weight: bold;
    padding: 0 10px;
    line-height: 26px;
  }

  .pointer {
    cursor: pointer;
  }
}

.noPlacard {
  background-color: #161348;
  /* 将动画效果应用到需要滚动的元素上 */
  animation: noScroll 1s ease-out 1;
}

.placard {
  background-color: #161348;
  /* 将动画效果应用到需要滚动的元素上 */
  animation: scroll 1s ease-out 1;
  /* 动画名称、动画时长、动画速度曲线、动画重复次数 */
}

/* 定义一个动画效果 */
@keyframes scroll {
  0% {
    opacity: 0.3;
  }

  100% {
    opacity: 1;
  }
}

@keyframes noScroll {
  0% {
    opacity: 0.3;
  }

  100% {
    opacity: 1;
  }
}

::v-deep .custom-class {
  min-width: 740px;
}

.pc-show-box {
  .common-layout {
    min-width: 1400px;
  }
}

// .pc-show-box .header {
//   // background: var(--anchor-color);
//   background: var(--anchor-color);
//   height: 80px;
//   border-bottom: 1px solid var(--boder-color);
//   width: 100%;
//   position: sticky;
//   top: 0;
//   z-index: 999;
//   & > .wrapper {
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     .logo {
//       // width: 216px;
//       width: 152px;
//       height: 48px;
//       color: #123f8d;
//       & > a img {
//         width: 100%;
//         height: 100%;
//         border-radius: 8px;
//       }
//     }
//   }
// }
// 主体
.theme {
  position: fixed;
  right: 10px;
  bottom: 80px;
  z-index: 999;
  opacity: 0.8;
  cursor: pointer;

  div {
    background: var(--anchor-color);
    padding: 5px 10px;
    border-radius: 5px;
    box-shadow: 0px 0px 16px 0px var(--input-shadow);
  }
}

// 会员群
.membership-group {
  bottom: 122px !important;
}

.router-link-exact-active {
  color: #42b983;
}

.app-view .router-link-exact-active {
  color: #297fff;
}

// .view-cont {
//   min-height: calc(100vh - 140px);
//   margin-bottom: 20px;
// }

.navigation-but {
  display: flex;
  flex: 1;
  margin-left: 80px;

  &>li {
    flex: 1;
  }

  &>li a {
    height: 100%;
    width: 100%;
    text-align: center;
    float: left;
    line-height: 80px;
    color: var(--text-color);
    font-size: 18px;
    cursor: pointer;

    &>i {
      font-size: 16px;
      margin-right: 10px;
      vertical-align: baseline;
    }
  }

  .router-link-active,
  a:hover {
    color: #123f8d;

    i {
      color: #123f8d;
    }
  }
}

::v-deep .search .el-input .el-input__wrapper {
  background: var(--anchor-color);
}

.app-show-box {
  // height: 100vh;
  // overflow: hidden;
  position: relative;

  .top-sticky {
    position: sticky;
    top: 0.42rem;
    z-index: 10000;
  }

  .header {
    height: 0.44rem;
    line-height: 0.44rem;
    text-align: center;
    font-size: 0.18rem;
    font-weight: 500;
    color: var(--text-color);
    background: var(--anchor-color);
    border-bottom: 0.01rem solid #eaeaea;
    // position: relative;
    position: sticky;
    top: -0.01rem;
    z-index: 10000;

    .logo {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0.16rem;
      margin: auto;
      width: 0.28rem;
      height: 0.28rem;
      color: #123f8d;

      &>a img {
        width: 100%;
        height: 100%;
        border-radius: 0.08rem;
      }
    }
  }

  .app-view {
    height: calc(100vh - 0.84rem);
    position: relative;
  }

  .appLogin {
    height: calc(100vh - 0.45rem) !important;
  }

  .app-tabbar {
    width: 100%;
    height: 0.49rem;
  }

  .app-tabbar-but {
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 0.1rem;
    text-align: center;
    height: 0.49rem;
  }

  .custom {
    width: 80px;
    font-size: 14px;
    text-align: center;
  }


}
</style>
