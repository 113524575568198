export default {
  1: "操作失败",
  // 通用参数
  1001: "参数不足",
  1002: "无效参数",
  1003: "不支持的文件类型",
  // 登录相关
  1100: "未登录",
  1101: "无效TOKEN",
  1102: "登录已失效，请重新登录",
  // 1102: "TOKEN过期",
  1103: "验证码错误",
  1104: "验证码过期",
  1105: "超出获取验证码次数限制",
  // 用户相关
  1201: "用户不存在",
  1202: "用户密码错误",
  1203: "公司不存在",
  1204: "用户名已占用",
  1205: "用户或公司不存在",
  1206: "用户未通过协议条款",
  1207: "没有更新公司信息的权限",
  1208: "没有创建公司的权限",
  1209: "公司名重复",
  1210: "公司信息不完整",
  1211: "用户基本信息不完整",
  1212: "手机号已绑定其他用户",
  1213: "邮箱已绑定其他用户",
  // 应用相关
  1301: "应用子类型不存在",
  1302: "应用类型不存在",
  1303: "应用不存在",
  1304: "应用行业类型不存在",
  1305: "没有权限下架该应用",
  1306: "应用重名错误",
  1307: "请先取消首页应用推荐，再下架应用。",
  // 资讯
  1401: "资讯子类型不存在",
  1402: "资讯类型不存在",
  1403: "资讯不存在",
  // 活动
  1501: "活动子类型不存在",
  1502: "活动类型不存在",
  1503: "活动不存在",
  1504: "活动已过期",
  1505: "活动已下架",
  // 培训
  1601: "培训内容不存在",
  1602: "内容已下架",
  1900: "系统错误",
  // 爬虫
  2000: "爬虫服务连接异常",
  // 第三方授权
  2100: "访问令牌已过期",
  2101: "非授权应用",
  2102: "令牌校验失败",
  // 社区接口部分
  2400: '帖子不存在',
  2401: '文件不存在',
  2402: '帖子已删除',
  2403: '当前帖子未发布',
};
