<template>
  <div class="card" @click.stop="goNews">
    <div class="image">
      <img :src="baseUrl + docItem.cover" alt="">
    </div>
    <div class="content">
      <a href="#">
        <span class="title one-apostrophe">
          {{ docItem.title }}
        </span>
      </a>

      <p class="desc two-apostrophe">
        {{ docItem.description }}
      </p>
    </div>
  </div>
</template>
<script setup>
import { ref } from 'vue';

const props = defineProps({
  docItem: {
    type: Object,
    default: () => ({})
  }
})
let baseUrl = ref(process.env.VUE_APP_BASE_URI);
const goNews = () => {
  // window.open(props.docItem.documentUrl)
  window.location.href = props.docItem.documentUrl
}
</script>
<style scoped lang="scss">
/* From Uiverse.io by Yaya12085 */
.card {
  cursor: pointer;
  /* max-width: 300px; */
  width: 100%;
  border-radius: 0.5rem;
  background-color: #fff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  border: 1px solid transparent;
  overflow: hidden;
}

.card a {
  text-decoration: none
}

.content {
  padding: 1.1rem;
}

.image {
  object-fit: cover;
  width: 100%;
  height: 250px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.title {
  color: #111827;
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 600;
}

.desc {
  margin-top: 0.5rem;
  color: #6B7280;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.action {
  display: inline-flex;
  margin-top: 1rem;
  color: #ffffff;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  align-items: center;
  gap: 0.25rem;
  background-color: #2563EB;
  padding: 4px 8px;
  border-radius: 4px;
}

.action span {
  transition: .3s ease;
}

.action:hover span {
  transform: translateX(4px);
}
</style>