<template>
  <el-dialog v-model="showReply" :title="title" width="800">
    <WangEditor ref="wangEditorRef" v-model="valueHtml"></WangEditor>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="close">取消</el-button>
        <el-button type="primary" @click="submit">确定</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import { ref, nextTick } from 'vue'
import WangEditor from '@/components/WangEditor/index.vue'

const emit = defineEmits(['submitReply'])

const valueHtml = ref('')
const wangEditorRef = ref(null)

const showReply = ref(false)
const title = ref('回复评论')

const open = (data) => {
  showReply.value = true
  nextTick(() => {
    if (data) {
      title.value = data.title
      valueHtml.value = data.content
      wangEditorRef.value.setContent(data.content)
    }
  })
}

// 提交
const submit = () => {
  emit('submitReply', valueHtml.value)
  close()
}

const close = () => {
  showReply.value = false
  clear()
}

const clear = () => {
  valueHtml.value = ''
  wangEditorRef.value.clear()
}
defineExpose({
  open
})
</script>
<style scoped lang="scss"></style>