<template>
  <div class="data-item">
    <div class="card-top">
      <!-- img -->
      <el-image
        class="card-img-size"
        :src="list.icon ? list.icon : src"
        fit="cover"
      />
      <div class="card-info">
        <div>
          <h2>{{ list.userName ? list.userName : list.companyName }}</h2>
          <span>{{ list.operatingPost }}</span>
        </div>

        <div class="operate">
          <div class="l-info">
            <i class="iconfont">&#xe8c4;</i> <span>{{ list.vcard_time }}</span>
            <br />
            <i class="iconfont">&#xe63f;</i>
            <span>{{ list.cityName + list.districtName }}</span>
          </div>
        </div>
        <div class="r-info">
          <div
            :class="{ 'collect-on': list.isCollect, collect: true }"
            @click="collectClick(list)"
          >
            <i class="iconfont">&#xe8c6;</i> <span>收藏</span>
          </div>
        </div>
      </div>
    </div>
    <div class="card-bottom">
      <div class="address"></div>
    </div>
    <div class="intro">{{ list.intro }}</div>
    <!-- <div class="tag">个人</div> -->
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits } from "vue";
let src = ref(require("@/assets/img/ai08.png"));
const emit = defineEmits(["change-tagList"]);
import { socialCollection } from "@/api/ai";
const props = defineProps({
  list: {
    type: Object,
    default: {},
  },
});
async function collectClick(list) {
  await socialCollection({ uid: list.uid, cid: list.cid, type: 3 });
  emit("change-activeList", list);
}
</script>
<style lang="scss" scoped>
.data-item {
  padding: 0.1rem;
  width: 100%;
  height: 5rem;
  overflow-y: scroll;
  // height: auto;
  background: var(--min-box-color);
  margin: 0 0.15rem 0.15rem 0;
  .tag {
    position: absolute;
    top: 0.02rem;
    right: -0.3rem;
    height: 0.25rem;
    line-height: 0.25rem;
    width: 0.9rem;
    background: #0070ec;
    color: #fff;
    font-size: 0.12rem;
    transform: rotate(45deg);
    text-align: center;
  }
  .card-top {
    display: flex;
  }
  .card-img-size {
    width: 0.68rem;
    height: 0.68rem;
    border-radius: 0.08rem;
    margin-right: 0.1rem;
  }
  .card-info {
    flex: 1;
    .operate {
      // display: flex;
      // align-items: center;
    }
    .l-info {
      // flex: 1;
    }
    .r-info {
      // width: 0.6rem;
      line-height: 0.16rem;
      .collect-on {
        cursor: pointer;
        i {
          color: #ffc220;
          font-size: 0.14rem;
        }
        span {
          font-size: 0.14rem;
        }
      }
    }
  }
  .card-info > div:nth-child(1) {
    overflow: hidden;
    & > h2 {
      font-size: 0.14rem;
      font-weight: 500;
      color: var(--text-color);
      float: left;
      line-height: 0.16rem;
    }
    & > span {
      display: block;
      float: left;
      font-size: 0.14rem;
      font-weight: 400;
      color: var(--text-color);
      line-height: 0.16rem;
      margin-left: 0.1rem;
    }
  }
  .card-info > div:nth-child(2) {
    // margin: 8px 0 16px 0;
    font-size: 0.14rem;
    font-weight: 400;
    color: var(--text-color);
    line-height: 0.18rem;
  }
  .card-info > div:nth-child(3) {
    & > i {
      font-size: 0.12rem;
    }
    & > span {
      font-size: 0.12rem;
      font-weight: 400;
      color: var(--text-color);
      line-height: 0.18rem;
      vertical-align: middle;
    }
  }
  .intro {
    font-size: 0.14rem;
  }
}
</style>
