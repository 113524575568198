<template>
  <div class="swiper-content">
    <swiper
      :modules="modules"
      :slidesPerView="1"
      :centeredSlides="flase"
      :spaceBetween="105"
      :virtual="true"
      :navigation="true"
      class="mySwiper"
      @swiper="setSwiperRef"
    >
      <swiper-slide
        v-for="(item, index) in dataList"
        :index="index"
        :key="index"
        >
        <img :src="item" @click="handleImage(item, index)"
      /></swiper-slide>
    </swiper>
  </div>
  <el-image-viewer
    v-if="imgPreviewUrl"
    :src="imgPreviewUrl"
    @close="closeViewer"
    :url-list="imgList"
    :initial-index="initialIndex"
  ></el-image-viewer>
</template>

<script setup>
// 引入swiper样式（按需导入）
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/virtual";
import { ref, onMounted } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination, Navigation, Virtual } from "swiper";
import { ApplicationItem } from "@/components";
let swiperRef = ref(null);
// 在modules加入要使用的模块
const modules = [Pagination, Navigation, Virtual];
const setSwiperRef = (swiper) => {
  swiperRef.value = swiper;
};
const props = defineProps({
  dataList: {
    type: Array,
    default: false,
  },
});
const slideTo = (index) => {
  swiperRef.value.slideTo(index - 1, 0);
};
onMounted(() => {
  slideTo(1);
});

let initialIndex = ref(0);
let imgList = ref([]);
let imgPreviewUrl = ref("");
// v-html 图片放大显示
function handleImage(e, index) {
  //获取点击的图片url,decodeURIComponent转码一下，防禁url转码
  imgPreviewUrl.value = decodeURIComponent(e);
  initialIndex.value = index;
  // imgPreviewUrl.value = e;
  imgList.value = props.dataList;
  //禁止遮罩层后面的内容滚动
  document.documentElement.style.overflowY = "hidden";
}
//关闭弹框
function closeViewer() {
  imgPreviewUrl.value = "";
  //恢复遮罩层后面的内容滚动
  document.documentElement.style.overflowY = "auto";
}
</script>
<style lang="scss" scoped>
body,
html {
  line-height: 0rem;
}
.swiper-content {
  width: 100%;
}
.swiper-content .swiper-slide {
  width: 1.2rem !important;
  height: 0.6rem !important;
  overflow: hidden;
  img {
    // width: 1.2rem !important;
    // height: 1.58rem !important;
    width: 100%;
    // max-width: 100%;
    max-height: 100%;
  }
}
::v-deep .swiper-wrapper,
.swiper-content .swiper-slide .swiper-slide-visible {
  height: 1.58rem !important;
}
::v-deep .swiper-button-prev,
::v-deep .swiper-rtl .swiper-button-next,
::v-deep .swiper-button-next,
::v-deep .swiper-rtl .swiper-button-prev {
  width: 0.32rem;
  height: 0.32rem;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 0.16rem;
  top: 0.94rem !important;
}
::v-deep .swiper-button-next:after,
::v-deep .swiper-button-prev:after,
::v-deep .swiper-button-next:after,
::v-deep .swiper-button-prev:after {
  color: #ffffff;
  font-size: 0.16rem;
}
</style>
