<template>
  <div class="data-item" :style="{ width }" :class="{ extend: extend }">
    <div class="top">
      <router-link :to="'/activity/detail?activeId=' + list.id" v-if="!extend">
        <el-image
          class="active-img"
          :src="list.icon ? baseUrl + list.icon : src"
          fit="cover"
        />
      </router-link>
      <router-link :to="'/activity/detail?activeId=' + list.id" v-else>
        <el-image class="active-img" :src="baseUrl + list.imageUrl" />
      </router-link>
      <h3>
        <router-link :to="'/activity/detail?activeId=' + list.id">
          {{ list.title }}</router-link
        >
      </h3>
    </div>
    <div class="bottom">
      <div class="left">
        <div>
          <i class="iconfont">&#xe8c4;</i> {{ list.start_date }} ～
          {{ list.end_date }}
        </div>
        <div><i class="iconfont">&#xe63f;</i> {{ list.address }}</div>
      </div>
      <div
        :class="{ 'collect-on': list.isCollect, collect: true }"
        @click="collectClcik(list)"
      >
        <i class="iconfont" title="收藏" :class="{ extend: extend }"
          >&#xe8c6;</i
        >
        <br v-if="!extend" />
        收藏
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineEmits, defineProps, ref } from "vue";
import { activityCollection } from "@/api/ai";
let src = ref(require("@/assets/img/ai01.png"));
const emit = defineEmits(["change-tagList"]);
const props = defineProps({
  extend: {
    type: Boolean,
    default: false,
  },
  list: {
    type: Object,
    default: {},
  },
  width: {
    type: String,
    default: "288px",
  },
});
let baseUrl = ref(process.env.VUE_APP_BASE_URI + "/media/");

async function collectClcik(list) {
  await activityCollection({ activityId: list.id, type: 3 });
  if (list.isCollect == 0) {
    list.isCollect = 1;
  } else {
    list.isCollect = 0;
  }
  emit("change-activeList", list);
}
</script>
<style lang="scss" scoped>
.data-item {
  width: 288px;
  height: 138px;
  background: var(--anchor-color);
  border-radius: 8px;
  padding: 10px;
  float: left;
  margin-bottom: 16px;
  .top {
    display: flex;
    .active-img {
      width: 60px;
      height: 60px;
      border-radius: 8px;
      margin-right: 10px;
    }
    h3 {
      font-size: 18px;
      color: var(--text-color);
      height: 56px;
      line-height: 28px;
      font-weight: bold;
      flex: 1;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
  .bottom {
    display: flex;
    margin-top: 10px;
    .left {
      flex: 1;
    }
    .left > div {
      color: #666;
      font-size: 14px;
      width: 200px;
      word-break: keep-all;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .collect {
      width: 32px;
      text-align: center;
      font-size: 12px;
      margin-top: -3px;
      cursor: pointer;
      i {
        // color: #ffc220;
        font-size: 20px;
      }
    }
    .extend {
      font-size: 14px !important;
    }
  }
}
.collect-on {
  i {
    color: #ffc220;
  }
}
.extend {
  width: 288px;
  height: 320px;
  .top {
    display: block;
  }
  .active-img {
    width: 100% !important;
    height: 150px !important;
    border-radius: 8px;
    margin-right: 10px;
  }
  .bottom {
    display: block;
    .left > div {
      width: 100%;
    }
    .collect {
      width: 100%;
      margin-top: 0;
      text-align: right;
    }
  }
}
</style>
