<template>
  <div class="pc-show-box" v-if="adaptiveStore.isShowView">
    <header-navigation></header-navigation>
    <div class="common-layout">
      <el-container class="container">
        <div style="background: var(--anchor-color)">
          <ComSideMenu v-if="isInlayPage"></ComSideMenu>
        </div>
        <el-container>
          <router-view />
        </el-container>
      </el-container>
    </div>
  </div>
  <!-- 移动端 -->
  <!-- <div class="app-show-box" v-if="!adaptiveStore.isShowView">
    <header class="header" v-if="!adaptiveStore.isShowView">
      {{ title }}
      <div class="logo">
        <router-link to="/web/index" v-if="appLogoShow">
          <img src="@/assets/img/appLogo.png" />
        </router-link>
        <a @click="goBack" v-else>
          <img src="@/assets/img/leftArrow.png" />
        </a>
      </div>
    </header>
    <app-pendant class="top-sticky" v-if="appLogoShow"></app-pendant>
    <router-view class="app-view" :class="{ appLogin: appShowPendant }">
    </router-view>
    <div class="tooltip">
      <FloatingBubble
        style="background: #fafafa;opacity: 0.8; width: 0.3rem; height: 0.3rem; color: #000; font-size: 0.12rem;"
        icon="down" @click="goAppDownload" />
    </div>
    <company-profile v-if="showPendant"></company-profile>
  </div> -->
</template>

<script setup>
import { ref, onMounted, watch, reactive, onBeforeUnmount } from "vue";
import { useRouter } from "vue-router";
import {
  AppPendant,
  HeaderNavigation,
  CompanyProfile,
  ComSideMenu,
} from "@/components";
import { FloatingBubble } from 'vant';
import LocalCache from "@/plugins/cache";
const router = useRouter();
// 星河（飞浆）
let isInlayPage = ref(true);
// 视频
let isUnfold = ref(true);
// 排行
let isShowRanking = ref(false);
let showPendant = ref(false);
let showHeader = ref(false);
// 首屏
let isShowPlacard = ref(true);
let ifRameSrc = ref("");
let title = ref("");
let appShowPendant = ref(false);
let appLogoShow = ref(false);
let LogoIcon = reactive([
  "/web/index",
  "/web/index/",
  "/news",
  "/application",
  "/connections",
  "/activity",
  "/train",
  "/personal",
]);
import {
  getHomeList,
  getUpgradeMembership,
  getPayStatus,
  getRecommend,
} from "@/api/ai";
import { ElMessage } from "element-plus";
import useDigitalman from "@/store/adaptive";
const adaptiveStore = useDigitalman();
watch(
  () => router.currentRoute.value,
  async (newValue) => {
    if (!adaptiveStore.appRecommendList) {
      const res = await getRecommend();
      adaptiveStore.appRecommendList = res.data.data;
    }
    if (JSON.stringify(newValue.query) !== "{}") {
      adaptiveStore.query = newValue.query;
    }
    if (newValue.path == "/application") {
      // 应用轮播图、排行
      getHomeList().then((res) => {
        adaptiveStore.bannerArr = res.data.data.swiper;
        adaptiveStore.rankList = res.data.data.rankList;
        isShowRanking.value = true;
      });
    } else {
      isShowRanking.value = false;
    }
    var clientWidth =
      document.documentElement.clientWidth || document.body.clientWidth;
    adaptiveStore.isShowView = clientWidth >= 768;
    title.value = newValue.meta.title;
    appLogoShow.value = LogoIcon.indexOf(newValue.path) !== -1 ? true : false;
    if (newValue.path == "/login" || newValue.path == "/agreement") {
      showPendant.value = false;
      appShowPendant.value = false;
    } else {
      showPendant.value = true;
      appShowPendant.value = true;
    }
    if (newValue.path == "/web/index" || newValue.path == "/web/index/") {
      // showPendant.value = false;
      showHeader.value = false;
      isShowPlacard.value = true; //首屏
      ifRameSrc.value = window.location.origin + "/ad/";
    } else {
      isShowPlacard.value = false; //首屏
      showHeader.value = true;
    }
    // 星河（飞浆）页面
    if (newValue.path == "/inlayPage") {
      isInlayPage.value = false;
    } else {
      isInlayPage.value = true;
    }
  },
  { immediate: true }
);


// App
function goBack() {
  let newValue = router.currentRoute.value;
  if (newValue.path === "/personal/application") {
    router.replace({ path: "/personal" });
    LocalCache.local.remove("listingData");
  } else if (newValue.path === "/personal/application/listing") {
    router.push("/personal/application");
  } else {
    router.back(-1);
  }
}

// 跳转到下载app
function goAppDownload() {
  router.push("/download");
}
</script>

<style lang="scss" scoped>
.video-bit {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 400px;
  background: var(--anchor-color);

  border-radius: 5px;
  z-index: 999;

  .title {
    i {
      vertical-align: baseline;
      font-size: 14px;
      cursor: pointer;
    }
  }

  .video-header {
    display: flex;
    justify-content: space-between;
    // color: #000;
    color: #666;
    font-weight: bold;
    padding: 0 10px;
    line-height: 26px;
  }

  .pointer {
    cursor: pointer;
  }
}

.noPlacard {
  background-color: #161348;
  /* 将动画效果应用到需要滚动的元素上 */
  animation: noScroll 1s ease-out 1;
}

.placard {
  background-color: #161348;
  /* 将动画效果应用到需要滚动的元素上 */
  animation: scroll 1s ease-out 1;
  /* 动画名称、动画时长、动画速度曲线、动画重复次数 */
}

/* 定义一个动画效果 */
@keyframes scroll {
  0% {
    opacity: 0.3;
  }

  100% {
    opacity: 1;
  }
}

@keyframes noScroll {
  0% {
    opacity: 0.3;
  }

  100% {
    opacity: 1;
  }
}

::v-deep .custom-class {
  min-width: 740px;
}

.pc-show-box {
  background: linear-gradient(to right bottom, #f2f7fd, #dee9f7);

  .common-layout {
    min-width: 1080px;
    max-width: 1426px;
    width: calc(100vw - 110px);
    margin: 0 auto;

    .container {
      box-sizing: border-box;
      background: linear-gradient(to right bottom, #f2f7fd, #dee9f7);
      padding: 0 60px;
    }
  }
}

// 主体
.theme {
  position: fixed;
  right: 10px;
  bottom: 10px;
  z-index: 999;
  opacity: 0.8;
  cursor: pointer;

  div {
    background: var(--anchor-color);
    padding: 5px 10px;
    border-radius: 5px;
    box-shadow: 0px 0px 16px 0px var(--input-shadow);
  }
}

// 会员群
.membership-group {
  bottom: 52px !important;
}

.router-link-exact-active {
  color: #42b983;
}

.app-view .router-link-exact-active {
  color: #297fff;
}



.navigation-but {
  display: flex;
  flex: 1;
  margin-left: 80px;

  &>li {
    flex: 1;
  }

  &>li a {
    height: 100%;
    width: 100%;
    text-align: center;
    float: left;
    line-height: 80px;
    color: var(--text-color);
    font-size: 18px;
    cursor: pointer;

    &>i {
      font-size: 16px;
      margin-right: 10px;
      vertical-align: baseline;
    }
  }

  .router-link-active,
  a:hover {
    color: #123f8d;

    i {
      color: #123f8d;
    }
  }
}

::v-deep .search .el-input .el-input__wrapper {
  background: var(--anchor-color);
}

.app-show-box {
  position: relative;

  .top-sticky {
    position: sticky;
    top: 0.42rem;
    z-index: 10000;
  }

  .header {
    height: 0.44rem;
    line-height: 0.44rem;
    text-align: center;
    font-size: 0.18rem;
    font-weight: 500;
    color: var(--text-color);
    background: var(--anchor-color);
    border-bottom: 0.01rem solid #eaeaea;
    position: sticky;
    top: -0.01rem;
    z-index: 10000;

    .logo {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0.16rem;
      margin: auto;
      width: 0.28rem;
      height: 0.28rem;
      color: #123f8d;

      &>a img {
        width: 100%;
        height: 100%;
        border-radius: 0.08rem;
      }
    }
  }

  .app-view {
    height: calc(100vh - 0.84rem);
    position: relative;
  }

  .appLogin {
    height: calc(100vh - 0.45rem) !important;
  }

  .app-tabbar {
    width: 100%;
    height: 0.49rem;
  }

  .app-tabbar-but {
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 0.1rem;
    text-align: center;
    height: 0.49rem;
  }

  .custom {
    width: 80px;
    font-size: 14px;
    text-align: center;
  }


}
</style>
