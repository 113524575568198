<template>
  <div
    class="data-item"
    :class="{ point: list.isCollect == 1 }"
    v-if="list.uid"
  >
    <div class="card-top" @click="dialogDetail(list)">
      <!-- img -->
      <div class="card-img">
        <em :class="'level' + list.userLevel">{{
          levelList[list.userLevel]
        }}</em>
        <el-image
          class="card-img-size"
          :src="baseUrl + list.icon"
          fit="cover"
        />
      </div>
      <div class="card-info">
        <div>
          <h2>{{ list.userName }}</h2>
          <span>{{ list.operatingPost }}</span>
        </div>
        <div>{{ list.intro }}</div>
        <div>
          <i class="iconfont">&#xe8c4;</i>
          <span>{{ formatTime(list.vcard_time, "{y}-{m}-{d}") }}</span>
        </div>
      </div>
    </div>
    <div class="card-bottom">
      <div class="address">{{ list.cityName + list.districtName }}</div>
      <div
        :class="{ 'collect-on': list.isCollect, collect: true }"
        @click="collectClcik(list)"
      >
        <i class="iconfont">&#xe8c6;</i> <span>收藏 </span>
      </div>
    </div>
    <div class="tag">个人</div>
  </div>

  <div class="data-item" :class="{ point: list.isCollect == 1 }" v-else>
    <div class="card-top" @click="dialogDetail(list)">
      <div class="card-img">
        <el-image
          class="card-img-size"
          :src="baseUrl + list.icon"
          fit="cover"
        />
      </div>
      <div class="card-info">
        <div>
          <h2>{{ list.companyName }}</h2>
        </div>
        <div>{{ list.intro }}</div>
        <div>
          <i class="iconfont">&#xe8c4;</i>
          <span>{{ formatTime(list.vcard_time, "{y}-{m}-{d}") }}</span>
        </div>
      </div>
    </div>
    <div class="card-bottom">
      <div class="address">{{ list.cityName + list.districtName }}</div>
      <div
        :class="{ 'collect-on': list.isCollect, collect: true }"
        @click="collectClcik(list)"
      >
        <i class="iconfont">&#xe8c6;</i> <span>收藏</span>
      </div>
    </div>
    <div class="tag comp">公司</div>
  </div>
</template>

<script setup>
import { defineProps, ref, defineEmits } from "vue";
import { formatTime } from "@/utils/ai";
import { socialCollection } from "@/api/ai";
const emit = defineEmits(["change-tagList", "dailog-value"]);

defineProps({
  list: {
    type: Object,
    default: {},
  },
});
let baseUrl = ref(process.env.VUE_APP_BASE_URI + "/media/");
let levelList = {
  0: "普通",
  1: "体验",
  2: "高级",
  3: "特级",
  4: "VIP",
};
function dialogDetail(list) {
  emit("dailog-value", list);

  // if (list.isCollect == 1) {
  //   emit("dailog-value", list);
  // } else {
  //   return;
  // }
}
async function collectClcik(list) {
  await socialCollection({ uid: list.uid, type: 3, cid: list.cid });
  if (list.isCollect == 0) {
    list.isCollect = 1;
  } else {
    list.isCollect = 0;
  }
  emit("collect-list", list);
}
</script>
<style lang="scss" scoped>
.data-item {
  padding: 0.08rem;
  background: var(--anchor-color);
  border-radius: 0.16rem;
  position: relative;
  overflow: hidden;
  &.point .card-top {
    cursor: pointer;
  }
  .tag {
    position: absolute;
    top: 0.02rem;
    right: -0.3rem;
    height: 0.25rem;
    line-height: 0.25rem;
    width: 0.9rem;
    background: #0070ec;
    color: #fff;
    font-size: 0.12rem;
    transform: rotate(45deg);
    text-align: center;
  }
  .tag.comp {
    background: #123f8d;
  }
  .card-top {
    display: flex;
  }
  .card-img {
    position: relative;
    width: 0.6rem;
    height: 0.6rem;
    margin-right: 0.08rem;
    img {
      display: block;
    }
    em {
      position: absolute;
      bottom: 0px;
      left: 0px;
      width: 100%;
      z-index: 1;
      display: block;
      font-style: normal;
      font-size: 0.12rem;
      border-radius: 0.08rem;
      height: 0.16rem;
      line-height: 0.16rem;
      text-align: center;
      color: #fff;
      background: #123f8d;
    }
    em.level1 {
      background: rgba($color: #123f8d, $alpha: 0.7);
    }
    em.level2 {
      background: rgba($color: #cb571c, $alpha: 0.7);
    }
    em.level3 {
      background: rgba($color: #8f93aa, $alpha: 0.7);
    }
    em.level4 {
      background: rgba($color: #ffc220, $alpha: 0.7);
    }
  }
  .card-img-size {
    width: 0.6rem;
    height: 0.6rem;
    border-radius: 0.08rem;
    margin-right: 0.08rem;
    float: left;
  }
  .card-info {
    flex: 1;
  }
  .card-info > div:nth-child(1) {
    overflow: hidden;
    line-height: 0.24rem;
    & > h2 {
      font-size: 0.16rem;
      font-weight: 500;
      color: var(--text-color);
      display: block;
      float: left;
      // line-height: 0.24rem;
      max-width: 2.5rem;
      word-break: keep-all;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    & > span {
      display: block;
      font-size: 0.12rem;
      font-weight: 400;
      color: var(--text-color);
      // line-height: 0.24rem;
      margin-left: 0.08rem;
    }
  }
  .card-info > div:nth-child(2) {
    margin: 0.04rem 0 0.04rem 0;
    font-size: 16px;
    font-weight: 400;
    color: var(--text-color);
    line-height: 24px;
    width: 260px;
    word-break: keep-all;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .card-info > div:nth-child(3) {
    & > i {
      font-size: 0.16rem;
      display: block;
      float: left;
      margin-right: 0.05rem;
    }
    & > span {
      display: block;
      float: left;
      font-size: 0.12rem;
      // font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: var(--text-color);
      line-height: 0.24rem;
      vertical-align: middle;
    }
  }
  .card-bottom {
    margin-top: 0.08rem;
    display: flex;
    .address {
      flex: 1;
      font-size: 0.12rem;
      font-weight: 400;
      color: var(--text-color);
      line-height: 0.22rem;
      display: block;
    }
    .collect {
      cursor: pointer;
      & > i {
        font-size: 0.14rem;
        color: var(--text-color);
        display: block;
        float: left;
        margin-right: 0.05rem;
      }
      & > span {
        font-size: 0.12rem;
        font-weight: 400;
        color: var(--text-color);
        line-height: 0.22rem;
        vertical-align: middle;
        float: left;
      }
    }
    .collect-on {
      i {
        color: #ffc220;
      }
    }
  }
}
</style>
