<template>
  <el-aside width="250px" class="menu-box">
    <!-- @close="handleClose" -->
    <el-menu @open="handleOpen" @select="handleSelect" unique-opened :default-active="currentIndex">
      <el-menu-item v-for="item in menuList" :index="item.key" :key="item.key" @click="goPage(item.key)">
        <el-icon><el-image :class="{ 'white-img': adaptiveStore.themeState }" class="img-icon"
            :src="activePath === item.key ? item.activeIconUrl : item.iconUrl"></el-image></el-icon>
        <div>
          <i class="icon-bg"></i>
          <span :class="{ active: activePath === item.key }">{{ item.name }}</span>
        </div>
      </el-menu-item>
    </el-menu>
    <!-- 标签区 -->
    <div class="tag-area">
      <h3 class="tag-title">热门标签</h3>
      <div class="tag-list">
        <TagItem @click="goTagDetail(tag)" :tag-content="tag.name" v-for="tag in communityHomeTags" :key="tag.id" />
      </div>
    </div>
  </el-aside>
</template>

<script setup>
// import { getCommonNewHome, getHomeFooter, getAppList } from "@/api/ai";
import { onMounted, ref, watch } from "vue";
import { TagItem } from '@/components';
import useDigitalman from "@/store/adaptive";
import { useRoute, useRouter } from "vue-router";
import useCommunityStore from "@/store/community";
import { storeToRefs } from "pinia";

const router = useRouter()

const goPage = (path) => {
  router.push(path)
}

const communityStore = useCommunityStore();
const { communityHomeTags } = storeToRefs(communityStore);

onMounted(() => {
  if (communityHomeTags.value.length === 0) {
    communityStore.getCommunityHomeData()
  }
})

const route = useRoute();

const activePath = ref(route.path);

const adaptiveStore = useDigitalman();

const menuList = ref([
  {
    key: '/aiCommunity/index',
    name: '首页',
    iconUrl: require('@/assets/img/com-1.png'),
    activeIconUrl: require('@/assets/img/com-active-1.png'),
  },
  {
    key: '/aiCommunity/community',
    name: '社区首页',
    iconUrl: require('@/assets/img/com-2.png'),
    activeIconUrl: require('@/assets/img/com-active-2.png'),
  },
  {
    key: '/aiCommunity/tag-cloud',
    name: '标签云',
    iconUrl: require('@/assets/img/com-3.png'),
    activeIconUrl: require('@/assets/img/com-active-3.png'),
  },
  {
    key: '/aiCommunity/ai-data',
    name: 'AI资料',
    iconUrl: require('@/assets/img/com-4.png'),
    activeIconUrl: require('@/assets/img/com-active-4.png'),
  },
  {
    key: '/aiCommunity/role-square',
    name: '角色广场',
    iconUrl: require('@/assets/img/com-5.png'),
    activeIconUrl: require('@/assets/img/com-active-5.png'),
  },
  {
    key: '/aiCommunity/image-square',
    name: '图片广场',
    iconUrl: require('@/assets/img/com-6.png'),
    activeIconUrl: require('@/assets/img/com-active-6.png'),
  },
]);

const goTagDetail = (tag) => {
  router.push({
    path: `/aiCommunity/tag-detail/${tag.id}`,
    query: {
      tagName: tag.name
    }
  })
}

// 当前激活菜单
const handleOpen = (key) => {
  activePath.value = key;
};
const handleSelect = (index) => {
  adaptiveStore.menuKey = index;
};

watch(() => route, (newValue) => {
  activePath.value = newValue.path;
}, { immediate: true })



</script>

<style lang="scss" scoped>
.el-menu {
  background: linear-gradient(to right bottom, #f9fafd, #e8f3fb);
}

.menu-box {
  position: sticky;
  top: 72px;
  background: var(--anchor-color);
  height: calc(100vh - 80px);
  overflow: hidden;
}

.el-menu-item {
  font-size: 16px;

  .active {
    font-weight: bold;
    color: #2468f2;
  }
}

::v-deep .el-sub-menu__title {
  font-size: 16px;
}

.img-icon {
  width: 22px !important;
  height: 22px !important;
}

.tag-area {
  height: 100%;
  background: linear-gradient(to right bottom, #f2f7fc, #e8f3fb);
  padding: 0 20px;
  padding-top: 20px;

  .tag-title {
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 500;
    color: #b3b7bd;
  }

  .tag-list {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;

  }
}
</style>
