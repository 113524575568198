<template>
  <div class="data-item" :class="type">
    <div class="top">
      <div class="name ellipsis">{{ slideList.labels[0] }}</div>
      <div class="company ellipsis" v-if="type">
        <i class="iconfont">&#xe65e;</i> {{ slideList.companyName }}
      </div>
    </div>
    <h5 class="title">
      <router-link :to="'/application/detail?id=' + slideList.appId">{{
        slideList.appName
      }}</router-link>
    </h5>
    <div class="prod-img">
      <router-link
        :to="'/application/detail?id=' + slideList.appId"
        v-if="vipState"
        ><img :src="slideList.cover"
      /></router-link>
      <img v-else @click="vipClick" :src="slideList.cover" />
    </div>
    <div class="bottom">
      <div class="time">
        <i class="iconfont">&#xe8c4;</i> {{ slideList.issueDate }}
      </div>
      <div class="time ellipsis" v-if="!type">
        <i class="iconfont">&#xe65e;</i> {{ slideList.companyName }}
      </div>
      <div class="operate">
        <span @click="operateClick(1, slideList)"
          ><i :class="['iconfont', slideList.isPraise ? 'icon-colour' : '']"
            >&#xec8c;</i
          >
          {{ slideList.praiseNum }}</span
        >
        <!-- <span @click="operateClick(2, slideList)"
          ><i :class="['iconfont', slideList.isTrample ? 'icon-colour' : '']"
            >&#xe697;</i
          >
          {{ slideList.trampleNum }}</span
        > -->
        <span @click="operateClick(3, slideList)"
          ><i :class="['iconfont', slideList.isCollect ? 'icon-colour' : '']"
            >&#xe8c6;</i
          >
          {{ slideList.collectNum }}</span
        >
        <span class="focus"><i class="iconfont">&#xe618;</i></span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { praise } from "@/api/ai";
import { getUser } from "@/utils/auth";
import { ElMessage } from "element-plus";
import { useRouter } from "vue-router";
const router = useRouter();
const props = defineProps({
  type: {
    type: String,
  },
  isCompany: {
    type: String,
  },
  slideList: {
    type: Object,
  },
  vipState: {
    type: Blob,
  },
});
// 会员点击应用
function vipClick() {
  if (getUser()) {
    let user = JSON.parse(getUser());
    user.userLevel > 0
      ? router.push({
          path: `/application/detail`,
          query: { id: props.slideList.appId },
        })
      : ElMessage.success("请充值为会员");
  } else {
    ElMessage.success("请登陆后进入应用");
  }
}
async function operateClick(key, item) {
  await praise({ type: key, appId: item.appId });
  let list = props.slideList;
  if (key == 1) {
    if (item.isPraise) {
      list.praiseNum = list.praiseNum - 1;
      list.isPraise = 0;
    } else {
      list.praiseNum = list.praiseNum + 1;
      list.isPraise = 1;
      if (list.isTrample) {
        list.isTrample = 0;
        list.trampleNum = list.trampleNum - 1;
      }
    }
  }
  if (key == 2) {
    if (item.isTrample) {
      list.trampleNum = list.trampleNum - 1;
      list.isTrample = 0;
    } else {
      list.trampleNum = list.trampleNum + 1;
      list.isTrample = 1;
      if (list.isPraise) {
        list.isPraise = 0;
        list.praiseNum = list.praiseNum - 1;
      }
    }
  }
  if (key == 3) {
    if (item.isCollect) {
      list.collectNum = list.collectNum - 1;
      list.isCollect = 0;
    } else {
      list.collectNum = list.collectNum + 1;
      list.isCollect = 1;
    }
  }
}
</script>
<style lang="scss" scoped>
.noShow {
  display: none !important;
}
.icon-colour {
  color: #273d83;
}
.data-item {
  // width: 460px;
  background: var(--anchor-color);
  // box-shadow: 0px 0px 0.08rem 0px var(--anchor-color);
  border-radius: 0.16rem;
  padding: 0.08rem;
  box-sizing: border-box;
  // margin-right: 0.08rem;
  margin-bottom: 0.08rem;
  .top {
    display: flex;
    justify-content: space-between;
    color: var(--text-color);
    margin-bottom: 0.1rem;
    .name {
      width: 1rem;
      height: 0.23rem;
      font-size: 0.14rem;
    }
    .company {
      width: 1.8rem;
      font-size: 0.16rem;
      text-align: right;
    }
  }
  h5.title {
    font-size: 0.16rem;
    margin-bottom: 0.08rem;
    font-weight: 500;
    color: var(--text-color);
  }
  .prod-img {
    width: 1.8rem;
    height: 1.8rem;
    border-radius: 0.16rem;
    overflow: hidden;
    margin: 0px auto 0.08rem;
  }
  .prod-img img {
    width: 100%;
  }
  .bottom {
    font-size: 0.12rem;
    // display: flex;
    // justify-content: space-between;
    color: var(--text-color);
    .operate {
      text-align: right;
    }
    .operate span {
      width: 100%;
      margin-right: 0.15rem;
      cursor: pointer;
    }
    .operate span.focus {
      color: #123f8d;
    }
    i {
      font-size: 0.16rem;
    }
    i.focus {
      color: #123f8d;
    }
  }
}
.data-item.study-b {
  // height: 3.38rem;
  flex: 1;
  .prod-img {
    width: 2.2rem;
    height: 2.2rem;
  }
  .bottom {
    display: flex;
    justify-content: space-between;
  }
}
.data-item.study-s {
  width: 100%;
  // height: 1.98rem;
  .prod-img {
    width: 100%;
    height: 0.8rem;
  }
  h5.title {
    margin-bottom: 0.08rem;
  }
  .prod-img {
    margin-bottom: 0.08rem;
    a {
      display: flex;
    }
  }
  .bottom {
    display: flex;
    justify-content: space-between;
  }
}
.data-item.study-s:first-child {
  margin-bottom: 0.08rem;
}
</style>
