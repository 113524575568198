<template>
  <div class="data-item" :class="{ extend: extend }">
    <div class="top">
      <router-link :to="'/train/detail?trainId=' + list.id">
        <el-image
          class="active-img"
          :src="list.imageUrl ? baseUrl + list.imageUrl : src"
          fit="cover"
        />
      </router-link>
      <h3>
        <router-link :to="'/train/detail?trainId=' + list.id">{{
          list.title
        }}</router-link>
      </h3>
    </div>
    <div class="bottom">
      <div class="left">
        <i class="iconfont">&#xe8c4;</i> {{ list.publish }}
      </div>
      <div
        :class="{ 'collect-on': list.isCollect, collect: true }"
        @click="collectClcik(list)"
      >
        <i class="iconfont icon-fontsize">&#xe8c6;</i> 收藏
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits } from "vue";
import { trainCollection } from "@/api/ai";
let src = ref(require("@/assets/img/ai01.png"));
let baseUrl = ref(process.env.VUE_APP_BASE_URI + "/media/");
const emit = defineEmits(["change-educationList"]);
const props = defineProps({
  extend: {
    type: Boolean,
    default: false,
  },
  list: {
    type: Object,
    default: {},
  },
});
async function collectClcik(list) {
  await trainCollection({ trainId: list.id, type: 3 });
  if (list.isCollect == 0) {
    list.isCollect = 1;
  } else {
    list.isCollect = 0;
  }
  emit("change-educationList", list);
}
</script>
<style lang="scss" scoped>
.data-item {
  // width: 288px;
  // height: 110px;
  width: 100%;
  overflow: hidden;
  background: var(--anchor-color);
  border-radius: 0.16rem;
  padding: 0.08rem;
  float: left;
  .top {
    display: flex;
    a {
      display: block;
      height: 0.6rem;
    }
    .active-img {
      display: block;
      width: 0.6rem;
      height: 0.6rem;
      border-radius: 0.08rem;
      margin-right: 0.08rem;
    }
    h3 {
      font-size: 0.16rem;
      color: var(--text-color);
      height: 0.56rem;
      line-height: 0.28rem;
      font-weight: bold;
      flex: 1;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
  .bottom {
    display: flex;
    margin-top: 0.08rem;
    .left {
      flex: 1;
      color: #666;
      font-size: 0.12rem;
      width: 2rem;
      word-break: keep-all;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .collect {
      width: 0.55rem;
      text-align: center;
      font-size: 0.12rem;
      // margin-top: -3px;
      cursor: pointer;
      i {
        // color: #ffc220;
        font-size: 0.2rem;
      }
    }
  }
}

.collect-on {
  i {
    color: #ffc220;
  }
}
.data-item.extend {
  width: 100%;
  overflow: hidden;
  // width: 288px;
  // height: 270px;
  .top {
    display: block;
    a {
      display: block;
      height: 1.83rem;
    }
    // height: 1.83rem;
    h3 {
      margin: 0.08rem 0;
      height: 0.24rem;
      line-height: 0.24rem;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      line-clamp: 1;
      -webkit-box-orient: vertical;
      a {
        display: block;
        height: 0.24rem;
      }
    }
  }
  .active-img {
    width: 100%;
    height: 1.83rem;
    border-radius: 0.08rem;
    // margin-right: 10px;
  }
}
</style>
