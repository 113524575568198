import router from './router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { getToken, getCookie, removeCookie, removeToken, removeUser } from '@/utils/auth'
import { ElMessage } from "element-plus";

NProgress.configure({ showSpinner: false })

// const whiteList = ['/login']

router.beforeEach((to, from, next) => {
  NProgress.start()
  if (to.query == '{}' || to.path != from.path) {
    // 兼容chrome
    document.body.scrollTop = 0
    // 兼容firefox
    document.documentElement.scrollTop = 0
    // 兼容safari
    window.pageYOffset = 0
  }
  if (getToken() && getCookie('cookie')) {
    if (to.path === '/login') {
      next({ path: '/personal' })
      NProgress.done()
    } else {
      next()
    }
  } else {
    // 没有token
    if (to.path.indexOf('/personal') == -1) {
      // 在免登录白名单，直接进入
      next()
    } else {
      if (getToken() && !getCookie('cookie')) {
        ElMessage.warning("登录过期，请重新登录");
      }
      removeCookie('cookie');
      removeToken();
      removeUser();
      next(`/login?redirect=${to.fullPath}`) // 否则全部重定向到登录页
      NProgress.done()
    }
  }
})


router.afterEach(() => {
  NProgress.done()
})
