<template>
  <div class="data-item" :style="{ width }" :class="{ extend: extend }">
    <div class="top">
      <h3 class="hide-over-2" @click="goPostDetail(list.id)">{{ list.title }}</h3>
      <div class="content hide-over-1">
        <div v-html="list.content"></div>
      </div>
    </div>
    <div class="bottom">
      <div class="left">
        <div class="item">
          <!-- <div class="time">
            {{ list.created_at }}
          </div> -->
        </div>
        <div class="item">
          <i class="iconfont">&#xe661;</i>
          <span>{{ list.hits }} 看过</span>
        </div>
        <div class="item">
          <i class="iconfont">&#xe63d;</i>
          <span>{{ list.comments }} 评论</span>
        </div>
        <div class="item">
          <i class="iconfont">&#xe63c;</i>
          <span>{{ list.praise }} 点赞</span>
        </div>
      </div>
      <div class="right" @click="$emit('cancel-collet',list.id)">
        <i title="收藏" class="iconfont icon-fontsize">&#xe8c6;</i> 收藏
      </div>
    </div>
  </div>
</template>
<script setup>
import { useRouter } from 'vue-router';

const router = useRouter()
defineProps({
  list: {
    type: Object,
    default: () => ({})
  }
})

const goPostDetail = (id) => {
  router.push(`/commonet/detail/${id}`) 
}
</script>
<style scoped lang="scss">
.data-item {
  width: 370px;
  min-height: 150px;
  background: var(--anchor-color);
  border-radius: 8px;
  padding: 20px;
  float: left;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .top {
    h3 {
      cursor: pointer;
      height: 30px;
      line-height: 30px;
      font-size: 18px;
      font-weight: 600;
      color: var(--text-color);
      margin-bottom: 10px;
    }

    
  }

  .bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;

    .left {
      display: flex;
      align-items: center;

      .item {
        display: flex;
        align-items: center;
        margin-right: 5px;
        cursor: pointer;
      }
    }

    .right {
      display: flex;
      align-items: center;
      cursor: pointer;
      .iconfont {
        color: #f6c44b;
      }
    }
  }
}
</style>
import { useRouter } from 'vue-router';
