<template>
  <div class="pc-show">
    <div class="company-profile">
      <el-row :gutter="20">
        <el-col :span="12">
          <div class="margin-bottom-20">
            <el-image style="height: 48px" :src="require('@/assets/img/logo.png')" :fit="contain" />
          </div>
          <div class="intro">
            AI门户是一款精品AI应用聚合平台。在这里，你可以找到全网最新的热门AI应用。<br />
            无论是你是公司创始人，市场人员，文职人员，还是开发人员，你都能在这里找到适合自己的AI应用。<br />
            利用AI工具，你可以大幅提高工作效率，从容不迫的获得职场优势。<br />
            AI不是淘汰人，而是淘汰不会用AI的人。选择AI门户，获取一站式AI应用分发便利服务。<br />
            你还等什么？和AI门户一起探索AI应用吧。
          </div>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="15">
            <el-col :span="7">
              <!-- <h3 class="intro-title margin-bottom-10">联系方式</h3>
              <p class="intro-phone maigin-bottom-30">
                郑小姐 19129981353 <br />
                王小姐 13392892806 <br />
              </p> -->
              <h3 class="intro-title margin-bottom-10">商务合作</h3>
              <!-- <p class="intro-phone">郑小姐 15012456201</p> -->
              <p class="intro-phone">郑小姐 19129981353</p>
              <!-- <span class="intro-phone">客<span style="margin-left: 13px">服</span> 19129981353</span> -->

            </el-col>
            <el-col :span="17">
              <el-row :gutter="15">
                <el-col :span="6">
                  <el-image style="width: 100%; height: 100%" :src="require('@/assets/img/wechatGroup2.png')" />
                </el-col>
                <el-col :span="6">
                  <el-image style="width: 100%; height: 100%" :src="require('@/assets/img/wechatGroup3.png')" />
                </el-col>
              </el-row>
              <el-row :gutter="15" style="margin-top: 10px;">
                <!-- <el-col :span="8">
                  <el-image fit="fill" style="width: 100%; height: 100%"
                    :src="require('@/assets/img/wechatGroup4.png')" />
                </el-col> -->
                <el-col :span="6">
                  <el-image style="width: 100%; height: 100%" :src="require('@/assets/img/wechatGroup.png')"></el-image>
                  <!-- <el-image
                  style="width: 100%; height: 100%"
                  src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg"
                  :fit="contain"
                /> -->
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>
  </div>
  <div class="app-show">
    <div class="company-profile">
      <el-row :gutter="20">
        <el-col :span="24">
          <div class="img-box">
            <el-image style="height: 0.48rem" :src="require('@/assets/img/logo.png')" :fit="contain" />
          </div>
          <div class="intro">
            AI门户是一款精品AI应用聚合平台。在这里，你可以找到全网最新的热门AI应用。<br />
            无论是你是公司创始人，市场人员，文职人员，还是开发人员，你都能在这里找到适合自己的AI应用。<br />
            利用AI工具，你可以大幅提高工作效率，从容不迫的获得职场优势。<br />
            AI不是淘汰人，而是淘汰不会用AI的人。选择AI门户，获取一站式AI应用分发便利服务。<br />
            你还等什么？和AI门户一起探索AI应用吧。
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script setup>
import useDigitalman from "@/store/adaptive";
const adaptiveStore = useDigitalman();
</script>

<style lang="scss" scoped>
.pc-show {
  .company-profile {
    width: 100%;
    // height: 298px;
    background: var(--anchor-color);
    padding: 40px 40px 40px 250px;
    border-bottom: 1px solid var(--min-box-color);

    .intro {
      font-size: 16px;
      font-weight: 400;
      color: var(--text-color) !important;
      // color: rgba(0, 0, 0, 0.85);
      line-height: 30px;
    }

    .intro-title {
      font-size: 18px;
      font-weight: 500;
      color: var(--text-color) !important;
      // color: #272727;
      line-height: 36px;
    }

    .intro-phone {
      font-size: 12px;
      font-weight: 400;
      color: var(--text-color) !important;
      // color: #666666;
      line-height: 22px;
    }
  }
}

.app-show {
  margin: 0.2rem 0 0rem;

  .el-image {
    overflow: initial;
  }

  .company-profile {
    width: 100%;
    // height: 298px;
    background: var(--anchor-color);
    padding: 0.2rem;
    border-bottom: 0.01rem solid var(--min-box-color);

    .intro {
      font-size: 0.16rem;
      font-weight: 400;
      color: var(--text-color) !important;
      // color: rgba(0, 0, 0, 0.85);
      line-height: 0.3rem;
    }

    .img-box {
      width: 1.55rem;
      height: 0.65rem;
      margin: auto;
    }
  }
}
</style>import { convert } from "@/utils/ai";
