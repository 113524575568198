<template>
  <div class="screen-box">
    <van-tree-select v-model:main-active-index="activeIndex" v-model:active-id="activeId"
      height="calc(100vh - 0.8734rem)" :items="items" @click-nav="navClick">
      <template #content>
        <van-collapse v-show="activeIndex == 0" v-model="activeName" accordion>
          <!-- <div
            class="ul-menu-type"
            style="padding: 0.1rem 0.16rem; color: #323233"
          >
            <a
              href="javascript:void(0)"
              :class="{ focus: queryParams.childCategory == 0 }"
              @click="screenChange('all', 1)"
            >
              全部
            </a>
          </div> -->
          <template v-for="(item, index) in categoryList" :key="index">
            <van-collapse-item v-if="item.name !== 'AI空间'" :name="item.id" @click="screenChange(item, 2)">
              <template #title>
                <img class="collapse-img" :src="item.iconUrl" />
                <div class="collapse-icon">
                  {{ item.name }}
                </div>
              </template>
              <div>
                <ul class="ul-menu-type">
                  <!-- <li>
                  <a
                    href="javascript:void(0)"
                    :class="{ focus: queryParams.childCategory == 0 }"
                    @click="screenChange('all', 3)"
                  >
                    全部
                  </a>
                </li> -->
                  <li v-for="(data, index) in childCategoryList" :key="index">
                    <a href="javascript:void(0)" :class="{ focus: queryParams.childCategory == data.id }"
                      @click="screenChange(data, 3)">
                      {{ data.name }}
                    </a>
                  </li>
                </ul>
              </div>
            </van-collapse-item>
          </template>

        </van-collapse>
        <!-- <van-collapse v-show="activeIndex == 1" v-model="activeName" accordion> -->
        <!-- <div
            class="search"
            :class="{ searchHover: queryParams.industryCategory == item.id }"
            v-for="(item, index) in industryList"
            :key="index"
            @click="screenChange(item, 4)"
          >
            <span>{{ item.name }}</span>
          </div> -->
        <!-- <van-collapse-item
            v-for="(item, index) in industryList"
            :key="index"
            :name="item.id"
            @click="screenChange(item, 4)"
          >
            <template #right-icon></template>
            <template #title>
              <div class="collapse-icon">
                {{ item.name }}
              </div>
            </template>
          </van-collapse-item> -->
        <!-- </van-collapse> -->
      </template>
    </van-tree-select>
  </div>
</template>

<script setup>
import { reactive, ref, onMounted, defineEmits, computed } from "vue";
import { getCategory, getCommonNewHome } from "@/api/ai";
import router from "@/router";
import useDigitalman from "@/store/adaptive";
import { getUser } from "@/utils/auth";
const adaptiveStore = useDigitalman();

const activeIndex = ref(0);
let activeId = ref(1);
// , { text: "行业" }
// let items = reactive([{ text: "类型" }, { text: "我的AI工具" }]);
const items = computed(() => {
  let userInfo = {}
  if (getUser()) {
    userInfo = JSON.parse(getUser());
    if (userInfo.userLevel > 0) {
      return [{ text: "类型" }, { text: "我的会员工具" }]
    }
    return [{ text: "类型" }]
  } else {
    userInfo = {}
    return [{ text: "类型" }]
  }
})
const activeName = ref("1");
let queryParams = ref({
  category: 0,
  childCategory: 0,
  industryCategory: 0,
});
let categoryList = ref([]);
let childCategoryList = ref();
let industryList = ref([]);
const emit = defineEmits(["change-tagList"]);
// 菜单选择
function screenChange(item, type) {
  activeId.value = item.id;
  if (type == 1) {
    queryParams.value = {
      category: 0,
      childCategory: 0,
      industryCategory: 0,
    };
    router.push({ path: "/application", query: { type: 0 } });
    emit("change-tagList", false);
  }
  if (type == 2) {
    // queryParams.value.category = item.id;
    // queryParams.value.childCategory = 0;
    if (item == "all") {
      queryParams.value.category = 0;
    } else {
      queryParams.value.category = item.id;
      childCategoryList.value = categoryList.value.find(
        (ele) => ele.id == item.id
      ).children;
    }
    // queryParams.value.childCategory = 0;
  }
  if (type == 3) {
    router.push({
      path: "/web/appMyAiTool",
      query: {
        title: item.name,
        type: 1,
        state: 2,
        category: queryParams.value.category,
        childCategory: item.id,
      },
    });
    // queryParams.value.childCategory = item.id || 0;
    // router.push({
    //   path: "/application",
    //   query: {
    //     type: router.currentRoute.value.query.type,
    //     typeid: queryParams.value.category,
    //     cid: item.id,
    //   },
    // });
    emit("change-tagList", false);
  }
  if (type == 4) {
    queryParams.value.industryCategory = item.id || 0;
    let type = (router.currentRoute.value.query.type = undefined
      ? 0
      : router.currentRoute.value.query.type);
    router.push({ path: "/application", query: { type: type, inid: item.id } });
    emit("change-tagList", false);
  }
}
onMounted(async () => {
  if (router.currentRoute.value.query.typeid != undefined) {
    queryParams.value.category = router.currentRoute.value.query.typeid;
    queryParams.value.industryCategory = 0;
  }
  if (router.currentRoute.value.query.cid != undefined) {
    queryParams.value.childCategory = router.currentRoute.value.query.cid || 0;
    queryParams.value.industryCategory = 0;
  }
  if (router.currentRoute.value.query.inid != undefined) {
    queryParams.value.industryCategory = router.currentRoute.value.query.inid;
    queryParams.value.category = 0;
    queryParams.value.childCategory = 0;
  }
  await getAppCategory();
});
// 一级菜单
function navClick(e) {
  if (e === 1) {
    router.push({
      path: "/web/vipAiTool",
      query: {
        title: "我的AI工具",
      },
    });
  }
}

async function getAppCategory() {
  // 分类数据
  // let list = await getCategory();

  let list = [];
  if (adaptiveStore.sectionData) {
    list = adaptiveStore.sectionData;
  } else {
    let res = await getCommonNewHome();
    list = res.data.data.sectionData;
  }
  categoryList.value = list.map((item) => {
    return {
      ...item,
      iconUrl:
        item.iconUrl && process.env.VUE_APP_BASE_URI + "/media/" + item.iconUrl,
    };
  });
  if (router.currentRoute.value.query.typeid != undefined) {
    childCategoryList.value = categoryList.value.find(
      (ele) => ele.id == router.currentRoute.value.query.typeid
    ).children;
  }
  let categoryArr = [...list];
  // categoryArr.unshift({
  //   id: "",
  //   name: "全部",
  // });
  industryList.value = categoryArr;
}
</script>

<style lang="scss" scoped>
.collapse-icon {
  font-size: 0.16rem;
  line-height: 0.24rem;
  // color: #123f8d;
  // i {
  //   font-size: 0.2rem;
  //   margin-right: 0.1rem;
  // }
}

.collapse-img {
  width: 0.24rem;
  height: 0.24rem;
  float: left;
  margin-right: 0.1rem;
}

.ul-menu-type {
  display: flex;
  flex-wrap: wrap;
  padding: 0 !important;

  li {
    margin: 6px 10px 6px 0;
  }

  a {
    display: block;
    padding: 5px 15px;
    font-size: 15px;
    border-radius: 4px;
  }

  a:hover,
  a.focus {
    background: linear-gradient(315deg, #b721ff 0%, #21d4fd 100%);
    color: #fff;
  }
}

.screen-box {
  height: calc(100vh - 0.84rem);
}

.search {
  min-height: 0.4rem;
  padding-left: 0.04rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  margin: 0 0.1rem;
  font-size: 0.14rem;
  color: #323233;
  font-weight: bold;
}

.searchHover {
  border-radius: 0.08rem;
  color: #123f8d;
  background: #f0f2f7;
}
</style>
