<template>
  <div class="data-item" :class="{ extend: extend }">
    <div class="top">
      <router-link :to="'/activity/detail?activeId=' + list.id" v-if="!extend">
        <el-image
          class="active-img"
          :src="list.icon ? baseUrl + list.icon : src"
          fit="cover"
        />
      </router-link>
      <router-link :to="'/activity/detail?activeId=' + list.id" v-if="extend">
        <el-image
          class="active-img"
          :src="baseUrl + list.imageUrl"
          fit="cover"
        />
      </router-link>
      <h3>
        <router-link :to="'/activity/detail?activeId=' + list.id">
          {{ list.title }}</router-link
        >
      </h3>
    </div>
    <div class="bottom">
      <div class="left">
        <div>
          <i class="iconfont">&#xe8c4;</i> {{ list.start_date }} ～
          {{ list.end_date }}
        </div>
        <div><i class="iconfont">&#xe63f;</i> {{ list.address }}</div>
      </div>
      <div
        :class="{ 'collect-on': list.isCollect, collect: true }"
        @click="collectClcik(list)"
      >
        <!-- :class="{ extend: extend }" -->
        <i class="iconfont extend">&#xe8c6;</i>
        <br />
        <!-- v-if="!extend" -->
        收藏
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineEmits, defineProps, ref } from "vue";
import { activityCollection } from "@/api/ai";
let src = ref(require("@/assets/img/ai01.png"));
const emit = defineEmits(["change-tagList"]);
const props = defineProps({
  extend: {
    type: Boolean,
    default: false,
  },
  list: {
    type: Object,
    default: {},
  },
});
let baseUrl = ref(process.env.VUE_APP_BASE_URI + "/media/");

async function collectClcik(list) {
  await activityCollection({ activityId: list.id, type: 3 });
  if (list.isCollect == 0) {
    list.isCollect = 1;
  } else {
    list.isCollect = 0;
  }
  emit("change-activeList", list);
}
</script>
<style lang="scss" scoped>
.data-item {
  // width: 288px;
  // height: 138px;
  overflow: hidden;
  width: 100%;
  background: var(--anchor-color);
  border-radius: 8px;
  padding: 10px;
  float: left;
  margin-bottom: 16px;
  .top {
    display: flex;
    a {
      display: block;
      height: 60px;
    }
    .active-img {
      display: block;
      width: 0.6rem;
      height: 0.6rem;
      border-radius: 0.08rem;
      margin-right: 0.08rem;
    }
    h3 {
      font-size: 0.16rem;
      color: var(--text-color);
      max-height: 0.48rem;
      line-height: 0.24rem;
      font-weight: bold;
      flex: 1;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
  .bottom {
    display: flex;
    margin-top: 0.08rem;
    .left {
      flex: 1;
    }
    .left > div {
      color: #666;
      font-size: 0.12rem;
      width: 2rem;
      word-break: keep-all;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .collect {
      width: 0.44rem;
      text-align: center;
      font-size: 0.12rem;
      margin-top: 4px;
      cursor: pointer;
      i {
        // color: #ffc220;
        font-size: 0.16rem;
      }
    }
    // .extend {
    //   font-size: 14px !important;
    // }
  }
}
.collect-on {
  i {
    color: #ffc220;
  }
}
.extend {
  // width: 288px;
  // height: 320px;
  overflow: hidden;
  width: 100%;
  .top {
    display: block;
    a {
      display: block;
      height: 1.83rem;
    }
    h3 {
      margin: 0.08rem 0;
      height: 0.24rem;
      line-height: 0.24rem;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      line-clamp: 1;
      -webkit-box-orient: vertical;
      a {
        display: block;
        height: 0.35rem;
      }
    }
  }
  .active-img {
    width: 100% !important;
    height: 1.83rem !important;
    border-radius: 0.08rem;
    // margin-right: 10px;
  }
  .bottom {
    margin-top: 0;
    //   display: block;
    //   .left > div {
    //     width: 100%;
    //   }
    //   .collect {
    //     width: 100%;
    //     margin-top: 0;
    //     text-align: right;
    //   }
  }
}
</style>
