<template>
  <div class="box">
    <div class="member-title">AI门户VIP会员 优质的AI应用</div>
    <div
      class="member-box"
      v-for="(item, index) in paymentList"
      :key="index"
      @click="paymentClick(item)"
    >
      <div
        class="member-level"
        :class="{ backgColor: item.level != paymentInfo.level }"
      >
        {{ item.name }}
      </div>
      <div class="member-price" v-if="item.online">
        <div>{{ item.intactFee / 100 }}元/年</div>
        <div>现价：{{ item.fee / 100 }}元</div>
      </div>
      <div v-else class="member-price">
        <div></div>
        <div>暂未开放</div>
      </div>
    </div>
    <div>
      <div v-if="activateCode" class="a_style">
        我的密钥：{{ view ? activateCode : "*********"
        }}<a @click="viewClick">查看</a>
      </div>
      会员权益：
      <p>1、享有定制应用大礼包（1-5款应用全年免费）；</p>
      <p>2、享有参加协会举办的线下公益（技能、创业）培训一次；</p>
      <p>3、推荐优质免费应用的信息推送（可选择订阅）；</p>
      <p>4、享有查看“人脉”普通会员及游客的信息；</p>
      <p>5、门户内拥有“黄金”头衔；</p>
      <p>6、享受其他未包含应用购买优惠折扣；</p>
      {{ entitlement }}
    </div>
    <van-button
      class="payment-button"
      @click="generate"
      :color="paymentInfo.online ? '#c72a29' : '#929292'"
      block
      type="primary"
      native-type="submit"
    >
      {{
        paymentInfo.online
          ? `支付¥${paymentInfo.fee / 100}元 开通${paymentInfo.name}`
          : "暂未开放"
      }}
    </van-button>
  </div>
</template>
<script setup>
import { ref, onMounted } from "vue";
import { getUpgradeMembership } from "@/api/ai";
import router from "@/router";
onMounted(() => {
  paymentClick();
});
// 密钥查看
let view = ref(false);
function viewClick() {
  view.value = !view.value;
}
// 付费弹框
let paymentList = ref([]);
let paymentInfo = ref({});
let activateCode = ref("");
// 支付弹框信息
async function paymentClick(item) {
  if (item && !item.online) return;
  let res = await getUpgradeMembership({ level: item ? item.level : "" });
  let list = res.data.data;
  paymentList.value = list.levelList;
  paymentInfo.value = list.upgrade;
  activateCode.value = list.activateCode;
  //   polling(list.upgrade.orderNo);
  //   dialogVisible.value = true;
}
import useDigitalman from "@/store/adaptive";
const adaptiveStore = useDigitalman();
function generate() {
  if (!paymentInfo.value.fee || !paymentInfo.value.online) return;
  adaptiveStore.paymentInfo = paymentInfo.value;
  router.push({ path: "/personal/appPayment" });
}
</script>
<style lang="scss" scoped>
.a_style {
  font-weight: bold;
  font-size: 0.16rem;
  a {
    color: #51b1f7;
    cursor: pointer;
    margin-left: 0.1rem;
  }
}
.box {
  font-size: 0.18rem;
  padding: 0 0.27rem;
}
.member-title {
  font-size: 0.22rem;
  text-align: center;
  font-weight: bolder;
  margin: 0.1rem 0 0.23rem;
}
.member-box {
  font-weight: bolder;
  border-radius: 0.14rem;
  background: #fff;
  //   width: 100%;
  margin: 0 0 0.23rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .member-level {
    background: url("../../assets/img/payment.png") 100% no-repeat;
    // background-size: 100%;
    height: 1rem;
    width: 2rem;
    line-height: 1rem;
    text-align: center;
    border-top-left-radius: 0.14rem;
  }
  .member-price {
    flex: 1;
    text-align: center;
    div:nth-child(1) {
      text-decoration: line-through;
    }
    div:nth-child(2) {
      color: #d9001b;
    }
  }
  .backgColor {
    background: url("../../assets/img/noPayment.png") 100% no-repeat !important;
  }
}
.payment-button {
  position: fixed;
  bottom: 0.18rem;
  left: 0;
  right: 0;
  width: 90%;
  margin: 0 auto;
}
.marg-bottom-20 {
  margin-bottom: 1rem;
}
</style>
