<template>
  <el-dialog
    v-model="props.phoneVerification"
    :before-close="handleClose"
    title="请验证手机号"
    width="400"
  >
    <template #header="{ close, titleId, titleClass }">
      <h1>请验证手机号</h1>
    </template>
    <h3 style="margin: -25px 0 25px">
      您好！应网络实名制相关法律法规要求，请尽快完成手机号绑定，感谢您的配合及支持！
    </h3>

    <el-form :model="form" ref="ruleFormRef" :rules="rules">
      <el-form-item label="" :label-width="0" prop="phoneNum">
        <el-input
          v-model.trim="form.phoneNum"
          placeholder="请输入您的手机号"
          class="input-with-select"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="" :label-width="0">
        <el-input
          v-model.trim="form.code"
          placeholder="请输入验证码"
          class="input-with-select"
        >
          <template #append>
            <el-button
              style="width: 125px"
              color="#bb271a"
              :disabled="codeCd"
              @click="verify(ruleFormRef)"
              >获取验证码<span v-if="codeCd">({{ long }})</span></el-button
            >
          </template>
        </el-input>
      </el-form-item>
      <el-form-item>
        <div style="width: 100%">
          <el-button
            @click="onSubmit"
            color="#bb271a"
            style="float: right; padding: 0 30px"
            >提交</el-button
          >
        </div>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script setup>
import { ref, reactive, defineProps, defineEmits } from "vue";
import { verifyCode, bindPhone } from "@/api/ai";
import { useRouter } from "vue-router";
const props = defineProps({
  phoneVerification: Boolean,
  isSkip: Boolean,
});
let form = ref({ phoneNum: "", code: "" });
let ruleFormRef = ref();
const rules = reactive({
  phoneNum: [{ required: true, validator: validatePass, trigger: "blur" }],
});
// 手机号校验
function validatePass(rule, value, callback) {
  const chinesePhoneNumberRegex = /^1[3456789]\d{9}$/;
  if (value === "") {
    callback(new Error("请输入"));
  } else if (!chinesePhoneNumberRegex.test(value)) {
    callback(new Error("手机号格式不正确"));
  } else {
    callback();
  }
}
// 验证码
let codeCd = ref(false);
let long = ref(60);
function verify(ruleFormRef) {
  ruleFormRef.validate(async (valid) => {
    if (valid) {
      // 获取验证码
      verifyCode({ phoneNum: form.value.phoneNum });
      // 开启计时
      codeCd.value = true;
      const timer = setInterval(() => {
        long.value--;
        if (long.value <= 0) {
          long.value = 60;
          codeCd.value = false;
          clearInterval(timer);
        }
      }, 1000);
    }
  });
}
// 校验手机号和验证码
const router = useRouter();
async function onSubmit() {
  await bindPhone({ ...form.value });
  if (props.isSkip) {
    router.push("/personal");
  } else {
    handleClose();
  }
}
// 控制弹窗关闭
const emits = defineEmits(["set-show"]);
function handleClose() {
  //关闭对话框时并向子组件传参
  emits("set-show", false);
}
</script>

<style></style>
