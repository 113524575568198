<template>
  <div class="data-item" @click="toDetail(rankList.appId)">
    <div class="img">
      <img :src="props.rankList.icon" />
    </div>
    <div class="content">
      <span class="ellipsis"
        ><em>{{ rankList.rank }}</em
        >{{ rankList.appName }}</span
      >
      <a href="javasript:void(0)">查看</a>
    </div>
  </div>
</template>

<script setup>
import { defineProps, onMounted } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();
const props = defineProps({
  rankList: {
    type: Object,
    default: {},
  },
});
onMounted(() => {
  // 开发环境是直接连接的后端
  let baseUri = process.env.VUE_APP_BASE_URI + "/media/";
  if ("production" == process.env.ENV) {
    // 生产环境通过代理访问后端
    baseUri += process.env.VUE_APP_BASE_API;
  }
  // baseUri += "/media/";

  // props.rankList.icon = baseUri + props.rankList.icon;
});
function toDetail(id) {
  router.push({ path: "/application/detail", query: { id: id } });
}
</script>
<style lang="scss" scoped>
.data-item {
  // width: 2.85rem;
  // margin-right: 0.2rem;
  display: flex;
  cursor: pointer;
  height: 0.56rem;
  line-height: 0.56rem;
  align-items: center;
  width: 2.9rem;
  margin-left: 0.22rem;
  .img {
    width: 0.4rem;
    height: 0.4rem;
    border-radius: 0.05rem;
    overflow: hidden;
    margin-right: 0.08rem;
  }

  .img img {
    width: 100%;
    display: block;
  }

  .content {
    flex: 1;
    // padding-bottom: 0.12rem;
    border-bottom: 0.01rem solid var(--line-color);
    display: flex;

    span {
      display: inline-block;
      width: 1.932rem;
      font-size: 0.14rem;
      color: var(--text-color);
      // flex: 1;
    }

    em {
      font-size: 0.14rem;
      font-style: normal;
      margin-right: 0.1rem;
    }

    a {
      display: inline-block;
      width: 0.44rem;
      height: 0.28rem;
      line-height: 0.28rem;
      border-radius: 0.04rem;
      text-align: center;
      color: #123f8d;
      border: 0.01rem solid var(--anchor-color);
      font-size: 0.14rem;
      margin: auto;
    }
  }
}

.data-item:nth-child(3n) {
  margin-right: 0px;
}
</style>
