<template>
  <div class="vip-item" @click.stop="skipChange">
    <el-image :src="baseUrl + appItem.cover" fit="cover" />
    <div class="content">
      <div class="title">{{ appItem.appName }}</div>
      <div class="desc two-apostrophe">{{ appItem.intro }}</div>
    </div>
  </div>
</template>
<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
let baseUrl = ref(process.env.VUE_APP_BASE_URI + "/media/");
const props = defineProps({
  appItem: {
    type: Object,
    default: () => ({})
  }
})

const router = useRouter()

// 跳转
function skipChange() {
  router.push({
    path: "/application/detail",
    query: { id: props.appItem.id || props.appItem.appId },
  });
}
</script>
<style scoped lang="scss">
.vip-item {
  cursor: pointer;
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  .el-image {
    width: 100%;
    height: 250px;
    object-fit: cover;
  }

  .content {
    padding: 20px;
  }

  .title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .desc {
    font-size: 14px;
    color: #666;
    margin-bottom: 10px;

  }
}
</style>