import axios from "axios";
import qs from "qs";
import { getToken } from "@/utils/auth";
import { tansParams } from "@/utils/ai";
import { ElMessage } from "element-plus";
import errorCode from "@/utils/errorCode";
import { removeCookie, removeToken, removeUser } from "@/utils/auth";
import router from "@/router";

// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: process.env.VUE_APP_BASE_API,
  // baseURL: "/dev-api",
  withCredentials: true,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
    Accept: "*/*",
  },
  // 超时
  timeout: 2 * 60000,
});


export const upload = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 60000
})

service.interceptors.request.use(
  (config) => {
    // 将请求数据转换成功 formdata 接收格式
    config.data = qs.stringify(config.data);
    // return config
    return config;
  },
  (error) => {
    return Promise.reject();
  }
);

function requestInterceptors(config) {
  // 是否需要设置 token
  const isToken = config.headers.isToken;
  // 是否需要防止数据重复提交
  // const isRepeatSubmit = (config.headers || {}).repeatSubmit === false
  if (getToken() && isToken) {
    config.headers["Authorization"] = "Bearer" + getToken(); // 让每个请求携带自定义token 请根据实际情况自行修改
  }

  // get请求映射params参数
  if (config.method === "get" && config.params) {
    let url = config.url + "?" + tansParams(config.params);
    url = url.slice(0, -1);
    config.params = {};
    config.url = url;
  }
  return config;
}

// request拦截器
upload.interceptors.request.use(requestInterceptors,
  (error) => {
    Promise.reject(error);
  }
);

// request拦截器
service.interceptors.request.use(requestInterceptors,
  (error) => {
    Promise.reject(error);
  }
);

function responseInterceptors(response) {
  const code = response.data.code || 0;
  if (code === 0 || code === 1211) {
    return response;
  } else if (code >= 1100 && code < 1199) {
    ElMessage.error(errorCode[code]);
    removeCookie('cookie');
    removeToken();
    removeUser();
    const redirect = window.location.pathname
    setTimeout(() => {
      router.push(`/login?redirect=${redirect}`)
      // location.href = "/login?redirect=" + redirect;
    }, 1000);
  } else {
    ElMessage.error(errorCode[code]);
  }
  // return response;
  return Promise.reject(new Error(errorCode[code]));
}

service.interceptors.response.use(responseInterceptors);
upload.interceptors.response.use(responseInterceptors);

export default service;