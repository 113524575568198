//定义关于counter的store
import { defineStore } from 'pinia'

//defineStore 是返回一个函数 函数命名最好有use前缀，根据函数来进行下一步操作
const useAdaptive = defineStore('adaptive', {
  state: () => {
    isShowView: false;
    rankList: [];
    bannerArr: [];
    scrollTop: 0;
    top100: [];
    themeState: false;
    search: '';
    query: '';
    paymentInfo: { };
    userLevel: '';
    userImg: '';
    appSearch: '';
    menuKey: '';
    isSignIn: false;
    sectionData: [];
    appRecommendList: [];
    showBanner: false;
  }
})


export default useAdaptive